import { LabeledFile } from "./edit"
import { ExtendedLayouts } from "@/common/lib/types"
import {
  DossierLevel,
  EntityDataEntityIdentifier,
  ExternalWidgetEntry,
  PersonCompanyRoleType,
} from "./storage"
import { IconType } from "@/common/store/dossier"

// Preview: Social Media Type
export type SocialMediaTypeX = {
  url: string
  name: string
  groupName: string
  content?: string
  date?: string
  followers?: number
  icon: IconType | string
  type: string
  color: string
  entities: EntityDataEntityIdentifier[]
}

export const SOCIAL_MEDIA_TYPE_X_FIELDS: (keyof SocialMediaTypeX)[] = [
  "url",
  "name",
  "date",
  "followers",
  "content",
  "icon",
  "color",
  "groupName",
]

// ChronologyType
export type ChronologyTypeX = {
  date: string
  title: string
  source?: string
  important?: boolean
}

export const CHRONOLOGY_TYPE_X_FIELDS: (keyof ChronologyTypeX)[] = [
  "date",
  "title",
  "source",
  "important",
]

export type DateGranularity = "year" | "month" | "day" | "time"
export type EventType =
  | "socialMedia"
  | "social"
  | "post"
  | "image"
  | "document"
  | "press"
  | "other"
  | "company_db"
  | "person_db"
  | "website"

export const GRANULARITY_MAP: Record<DateGranularity, number> = {
  year: 0,
  month: 1,
  day: 2,
  time: 3,
}

export type EventInfo = {
  date: Date
  granularity?: DateGranularity
  title: string
  source?: string
  icon?: string
  type?: EventType
  important?: boolean
  entities?: EntityDataEntityIdentifier[]
}

export type ReportType =
  | "general"
  | "darknet"
  | "machinelearningtools"
  | "other"

export type ReportInfo = {
  type: ReportType
  title?: string
  richContent: string
  drawerOrder?: number
}

export type LocationType = "company" | "person" | "other"

export type LocationInfo = {
  type: LocationType
  pos: [number, number]
  size: number
  address?: string
  title?: string
  label?: string
  icon?: string
  color?: string
  entities: EntityDataEntityIdentifier[]
}

export type ImageInfo = {
  globalFeatured: boolean
  featured: boolean
  lazyUrl: () => Promise<string>
  thumbnailUrl: string
  dimensions?: [number, number]
  title?: string
  source?: string
  date?: string
  entities: EntityDataEntityIdentifier[]
}

export type WebSnapshotInfo = Omit<LabeledFile, "date"> & {
  title: string
  type: "press" | "other " | "company_db" | "person_db" | "website" | "post"
  date?: Date
  important?: boolean
  entities?: EntityDataEntityIdentifier[]
}

export type KpiData = {
  dimension: string
  value: number
  levels: {
    value: number
    label: string
  }[]
}

export type MetricInfo = {
  title?: string
  type: "kpi"
} & {
  type: "kpi"
  data: KpiData[]
}

// Dossier View
export type DossierView = {
  meta: {
    url: string
    code_name: string
    level: DossierLevel
    created_at: Date
    lastChange?: Date
    title?: string
  }
  emails: string[]
  events: EventInfo[]
  reports: ReportInfo[]
  locations: LocationInfo[]
  images: ImageInfo[]
  social_media: SocialMediaTypeX[]
  web_snapshots: WebSnapshotInfo[]
  metrics: MetricInfo[]
  // externalSites: ExternalSiteType[],
  // additional: AdditionalType[],
  widgets: ExternalWidgetEntry[]
  entities: {
    [id: EntityDataEntityIdentifier]: EntityDataInfo
  }
  featured_snapshot?: WebSnapshotInfo
  predefinedNetworkLayout?: ExtendedLayouts
}

export type YearOrDate = number | Date
export type AddressInfo = {
  address: string
  label: string
}

export type DataValue = string | number | Date | YearOrDate | AddressInfo

export type SourcedValue<T = DataValue> = {
  value: T
  source?: string
}

export type EntityDataInfo = {
  id: EntityDataEntityIdentifier
  title: string
  initial?: string
  featuredImage?: ImageInfo
  addresses?: SourcedValue<AddressInfo>[]
  press?: WebSnapshotInfo[]
  otherInformation: {
    [key: string]: SourcedValue[]
  }
  widgets?: ExternalWidgetEntry[]
} & (
  | {
      type: "person"
      birthDate?: SourcedValue<YearOrDate>
      emails: (SourcedValue<string> & { key: string })[]
      phone_numbers: (SourcedValue<string> & { key: string })[]
      aliases: SourcedValue<string>[]
      relatedPersons: EntityDataEntityIdentifier[]
      relatedCompanies: {
        [company: EntityDataEntityIdentifier]: PersonCompanyRoleType
      }
    }
  | {
      type: "company"
      foundingDate?: SourcedValue<YearOrDate>
      register_id?: string
      purpose?: string
      relatedCompanies: EntityDataEntityIdentifier[]

      relatedPersons: {
        [person: EntityDataEntityIdentifier]: PersonCompanyRoleType
      }
    }
)
