<script lang="ts" setup>
import { useDossierStore } from "@/common/store/dossier"
import {
  EntityDataEntityIdentifier,
  EntityDataInfo,
} from "@/common/store/dossier/types"

const dossierStore = useDossierStore()
const props = defineProps<{
  usedEntities?: EntityDataEntityIdentifier[]
  entities: {
    [id: EntityDataEntityIdentifier]: EntityDataInfo
  }
  icon?: string
}>()
const getEntitiesImages = (data: any) => {
  let entities: any = []
  if (data && data.length) {
    data.forEach((ent: any) => {
      let img = props.entities && props.entities[ent].featuredImage
      props.entities && img && entities.push(img)
    })
  }
  return entities
}
</script>

<template>
  <template v-if="getEntitiesImages(usedEntities).length > 0">
    <v-avatar
      v-for="(img, index) in getEntitiesImages(usedEntities)"
      :key="index"
      :style="index > 0 ? `left: -${index * 12}px;` : ''"
      size="40"
      class="position-relative"
    >
      <v-img
        eager
        v-if="img.thumbnailUrl"
        :src="img.thumbnailUrl"
        :lazy-src="img.thumbnailUrl"
      />
    </v-avatar>
  </template>
  <v-avatar color="tertiary" v-else>
    <v-icon color="white" :icon="icon"></v-icon>
  </v-avatar>
</template>
<style lang="scss"></style>
