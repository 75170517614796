import { defineStore } from "pinia"
import { reactive, ref, Ref, shallowReactive } from "vue"
import { ApiUrl } from "@/common/lib/types"
import { ApiReturn } from "@/common/lib/api"
import ReactiveMap from "@/common/lib/ReactiveMap"
import { useApiStore } from "@/common/store/api"
import { TestableObject } from "@/common/lib/AtomicOperator"
import { isEqual } from "lodash"
import { removeProxy } from "@/common/lib/util"

/*
 * Stores "The Truth", which is a cached representation of all relevant server
 * objects. All fetching of payload should go through this store, and all updates
 * be applied to this store. Returns references to the live state which are updated
 * accordingly.
 * NO OTHER COMPONENT IS ALLOWED TO STORE SERVER STATE except as references from this truth store
 */
export const useTruthStore = defineStore("truth", () => {
  const api = useApiStore()
  const data = new ReactiveMap<ApiUrl, ApiReturn>({
    handleMissing: (url) => {
      ;(async () => {
        try {
          const val = (await api.GET(url)) as ApiReturn
          if (val) {
            data.set(url, val)
          }
        } catch (e) {
          console.error("Background job")
        }
      })()
      return undefined
    },
    normalizeKey(key: string) {
      return api.base.normalizeUrl(key)
    },
  })

  async function fetch<C extends ApiReturn = ApiReturn>(
    url: ApiUrl,
    options = { allowCached: true },
  ): Promise<Ref<C>> {
    if (options.allowCached && data.has(url)) {
      return data.get(url) as Ref<C>
    }
    const response = (await api.GET(url)) as C
    if (typeof response === "object" && Object.hasOwn(response, "url")) {
      data.set(url, response)
      return data.get(url) as Ref<C>
    } else {
      return ref(response) as Ref<C>
    }
  }

  async function patch<C extends ApiReturn = ApiReturn>(
    url: ApiUrl,
    data_: Record<string, any>,
  ): Promise<Ref<C>> {
    const url_ = api.base.normalizeUrl(url)
    const response = (await api.PATCH(url_, data_)) as C
    if (typeof response === "object" && Object.hasOwn(response, "url")) {
      data.set(url, response)
      return data.get(url) as Ref<C>
    } else {
      return ref(response) as Ref<C>
    }
  }

  type Items = (TestableObject | null | {})[]
  function update(items: Items): Items {
    for (const item of items) {
      if (item && Object.hasOwn(item, "url")) {
        const item_ = item as TestableObject
        if (!isEqual(data.getRightNow(item_.url), item_)) {
          data.set(item_.url, item_)
        }
      }
    }
    return items
  }

  async function prefetch(url: ApiUrl) {
    const items = await api.GET<Items>(url)
    update(items)
  }

  return {
    data,

    fetch,
    patch,
    update,

    prefetch,
  }
})
