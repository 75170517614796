<template>
  <div class="preview-page">
    <AppDrawer
      v-model="appDrawer"
      :otherReports="otherReports"
      :gallery="gallery"
      :dossierPreview="dossierPreview"
      :yearEvents="yearEvents"
    />
    <PhotoDrawer
      v-model="photoDrawer"
      :gallery="gallery"
      :entities="dossierPreview?.entities"
    />
    <SideDrawer
      :nav-list="navInfo"
      :featured-photo="featuredPhoto"
      :title="dossierPreview!.meta.title"
      :emails="dossierPreview!.emails"
      :social-media="dossierPreview!.social_media"
      :paragraph="mainReport?.richContent"
      @set-app-drawer="appDrawer = !appDrawer"
      @set-photo-drawer="photoDrawer = !photoDrawer"
      :created-date="dossierPreview!.meta.lastChange!"
    />
    <v-main class="knapp-grid ma-4">
      <!-- 1A, 1B Main Report -->
      <CardBlock
        v-if="mainReport"
        id="mainReport"
        :title="mainReport.title"
        class="bericht-block"
        tooltip="Bewertung durch Profiler"
      >
        <v-card-text class="pt-0"
          ><p
            class="rich-content narrow-scrollbar"
            v-html="mainReport.richContent"
          ></p>
        </v-card-text>
      </CardBlock>

      <template v-for="(report, i) of otherReports" :key="i">
        <CardBlock
          v-if="report.richContent"
          col-span="2"
          :title="report.title"
          :style="`order:${report.drawerOrder}`"
          :class="dossierStore.printMode ? 'd-print-block' : 'd-none'"
          class="mb-4"
        >
          <v-card-text
            ><div class="rich-content" v-html="report.richContent"></div
          ></v-card-text>
        </CardBlock>
      </template>

      <!--  News Block -->
      <CardBlock
        style="z-index: 9"
        v-if="pressClippings.length"
        tooltip="Archivierte Webseiten mit Links zur aktuellen URL"
        class="news-block d-print-none"
        id="press_clippings"
      >
        <NewsBlock
          :list="pressClippings"
          @set-app-drawer="appDrawer = $event"
        />
      </CardBlock>

      <!-- Chart Area -->
      <CardBlock
        title="Google Suchtreffer"
        tooltip="externe Quelle: Gezielte Suchen via Google"
        v-if="widgets.length"
        class="widget-block d-print-none"
      >
        <ExternalWidgets v-model="widgets" />
      </CardBlock>
      <template v-else-if="Object.keys(yearEvents).length">
        <div
          class="widget-block d-flex flex-column justify-space-between d-print-none"
        >
          <CardBlock
            class="h-100 mb-4"
            tooltip="Presse, Bilder, Dokumente, Social-Media et. al."
          >
            <template #title
              >{{ `${eventNumber} TREFFER BIS ZUM` }}
              <short-date :date="dossierPreview!.meta.lastChange!" />
            </template>
            <SparklineChart :model-value="yearEvents" />
          </CardBlock>
          <CardBlock
            class="social-media-graph-block h-100 d-print-none"
            title="Alle Suchergebnisse je Jahr"
            tooltip="Diagramm Suchergebnisse"
            v-if="yearEvents"
          >
            <GraphCategoryYear :model-value="yearEvents" />
          </CardBlock>
        </div>

        <!-- Social Media Chart -->
        <!-- <CardBlock
          title="Kontakte in Sozialen Medien"
          center
          v-if="dossierPreview!.social_media.some((x) => x.followers)"
        >
          <SocialMediaChart v-model="dossierPreview!.social_media" />
        </CardBlock> -->
      </template>

      <!-- Timeline -->
      <CardBlock
        color="timeline"
        border="2"
        class="timeline-block d-print-none"
        id="timeline"
        style="overflow: visible"
        v-if="dossierPreview!.events"
      >
        <TimelineBlock
          :events="dossierPreview!.events"
          :entities="dossierPreview?.entities"
          @toggle-app-drawer="appDrawer = true"
        />
      </CardBlock>

      <!-- Entities Nodes -->
      <CardBlock
        class="entities-nodes-block"
        title="Netzwerk"
        tooltip="Verbindungen von Unternehmen und Personen"
      >
        <main-graph-display
          :social-media="dossierPreview!.social_media"
          :entities="dossierPreview!.entities"
          @show-entity="showEntity = $event"
          @update:network-positions-out="networkPositionsOut = $event"
        />
      </CardBlock>

      <CardBlock
        class="person-list-block col-12 pa-3"
        :class="dossierStore.printMode ? 'd-print-block' : 'd-none'"
        title="Personen"
      >
        <PersonCard
          class="person-card"
          v-for="person in personEntities"
          :view="dossierPreview!"
          :entity="person"
          :key="person.id"
        />
      </CardBlock>

      <CardBlock
        class="company-list-block col-12 pa-3"
        :class="dossierStore.printMode ? 'd-print-block' : 'd-none'"
        title="Unternehmen"
      >
        <CompanyCard
          class="company-card"
          v-for="company in companyEntities"
          :view="dossierPreview!"
          :entity="company"
          :key="company.id"
        />
      </CardBlock>

      <CardBlock
        style="z-index: 9"
        v-if="pressClippings.length"
        :class="dossierStore.printMode ? 'd-print-block' : 'd-none'"
        class="news-press-block"
        id="press_clippings"
        tooltip="Archivierte Webseiten mit Links zur aktuellen URL"
      >
        <NewsBlock
          :entities="dossierPreview?.entities"
          :list="pressClippings.filter((item) => item.type === 'press')"
          @set-app-drawer="appDrawer = $event"
        />
      </CardBlock>

      <CardBlock
        style="z-index: 9"
        v-if="pressClippings.length"
        :class="dossierStore.printMode ? 'd-print-block' : 'd-none'"
        class="news-post-block"
        id="press_clippings"
        tooltip="Archivierte Webseiten mit Links zur aktuellen URL"
      >
        <NewsBlock
          :entities="dossierPreview?.entities"
          :list="pressClippings.filter((item) => item.type === 'post')"
          @set-app-drawer="appDrawer = $event"
        />
      </CardBlock>

      <!-- MAP -->
      <CardBlock
        class="map-block d-print-none"
        v-if="dossierPreview!.locations"
        title="KARTE"
        tooltip="Postleitzahlen aus dem Suchverlauf"
      >
        <MapView :model-value="dossierPreview!.locations" />
      </CardBlock>

      <!-- Social Media Accounts Nodes -->
      <CardBlock
        v-if="dossierPreview!.featured_snapshot"
        :title="
          dossierPreview!.featured_snapshot.label ||
          dossierPreview!.featured_snapshot.title
        "
        class="social-media-nodes-block d-print-none"
        tooltip="besonders hervorzuhebendes, relevantes Suchergebnis"
      >
        <FeaturedSnapshot :item="dossierPreview!.featured_snapshot" />
      </CardBlock>
      <CardBlock
        class="social-media-nodes-block d-print-none"
        v-else-if="dossierPreview!.social_media"
      >
        <SocialMediaGraph :items="dossierPreview!.social_media" />
      </CardBlock>

      <CardBlock
        v-if="loadedImages.length"
        :class="dossierStore.printMode ? 'd-print-block' : 'd-none'"
        class="mb-4 gallery-block"
        title="Fotos"
      >
        <v-container fluid>
          <v-row>
            <v-col
              v-for="(image, i) of loadedImages"
              :key="i"
              :cols="image.cols"
            >
              <v-card>
                <v-img
                  eager
                  :transition="false"
                  :lazy-src="image.thumbnailUrl"
                  :src="image.url"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </CardBlock>

      <v-dialog
        max-width="50em"
        width="80vw"
        v-if="showEntityDialog"
        v-model="showEntityDialog"
      >
        <entity-data-view :entity-id="showEntity!" :view="dossierPreview!">
          <template #close>
            <v-btn
              :icon="mdiClose"
              @click="showEntity = null"
              color="primary"
              variant="plain"
              size="small"
            />
          </template>
        </entity-data-view>
      </v-dialog>
    </v-main>
  </div>
</template>

<script setup lang="ts">
import { Ref, ref, watch } from "vue"
import {
  DossierView,
  EntityDataEntityIdentifier,
  EntityDataInfo,
  useDossierStore,
} from "@/common/store/dossier"

import { computed } from "vue"

import { mdiClose } from "@mdi/js"

import SideDrawer from "@/common/components/view/SideDrawer.vue"
import PhotoDrawer from "@/common/components/view/PhotoDrawer.vue"
import AppDrawer from "@/common/components/view/AppDrawer.vue"
import CardBlock from "@/common/components/view/CardBlock.vue"
import MapView from "@/common/components/view/MapView.vue"
import SparklineChart from "@/common/components/view/SparklineChart.vue"
import ExternalWidgets from "@/common/components/view/ExternalWidgets.vue"
import SocialMediaChart from "@/common/components/view/SocialMediaChart.vue"
import GraphCategoryYear from "@/common/components/view/GraphCategoryYear.vue"
import NewsBlock from "@/common/components/view/NewsBlock.vue"
import ShortDate from "@/common/components/ShortDate.vue"
import TimelineBlock from "@/common/components/view/TimelineBlock.vue"
import MainGraphDisplay from "@/common/components/view/MainGraphDisplay.vue"
import SocialMediaGraph from "@/common/components/view/SocialMediaGraph.vue"
import EntityDataView from "@/common/components/view/EntityDataView.vue"
import { useLayoutData } from "../useLayoutData"
import FeaturedSnapshot from "@/common/components/view/FeaturedSnapshot.vue"
import PersonCard from "@/common/components/view/PersonCard.vue"
import CompanyCard from "@/common/components/view/CompanyCard.vue"

const dossierStore = useDossierStore()
const props = defineProps<{
  dossierPreview: DossierView | null
  preview?: boolean
}>()
const networkPositionsOut = defineModel<any>("networkPositionsOut")

const {
  featuredPhoto,
  gallery,
  mainReport,
  navInfo,
  otherReports,
  pressClippings,
  yearEvents,
  eventNumber,
  widgets,
} = useLayoutData(props.dossierPreview, "knapp")

const showEntity = ref(null as EntityDataEntityIdentifier | null)
const appDrawer: Ref<boolean> = ref(false)
const photoDrawer: Ref<boolean> = ref(false)
const showEntityDialog = computed({
  get: () => !!showEntity.value,
  set: (value) => {
    if (!value) showEntity.value = null
  },
})

const personEntities = computed(() => {
  if (!props.dossierPreview?.entities) return []
  const entities = Object.values(props.dossierPreview?.entities).filter(
    (entity): entity is EntityDataInfo & { type: "person" } =>
      entity.type === "person",
  )
  return entities
})

const companyEntities = computed(() => {
  if (!props.dossierPreview?.entities) return []
  return Object.values(props.dossierPreview?.entities).filter(
    (entity): entity is EntityDataInfo & { type: "company" } =>
      entity.type === "company",
  )
})

const loadedImages = ref<any[]>([])

watch(
  gallery,
  async () => {
    if (!gallery.value.length) {
      loadedImages.value = []
      return
    }

    const images = await Promise.all(
      gallery.value.map(async (item: any) => ({
        url: await item.image.lazyUrl(),
        cols: item.image.cols,
        thumbnailUrl: item.image.thumbnailUrl,
      })),
    )

    loadedImages.value = images
  },
  { immediate: true },
)
</script>
<style lang="scss">
.preview-page {
  background-color: rgb(var(--v-theme-background));
}

.preview-drawer {
  background-color: rgb(var(--v-theme-secondary));
}

.knapp-grid {
  display: grid;
  gap: 36px;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows:
    minmax(100px, 250px) minmax(100px, max-content) auto minmax(
      100px,
      max-content
    )
    minmax(100px, max-content);
  grid-template-areas:
    "news news bericht widget widget"
    "news news bericht widget widget"
    "timeline timeline timeline timeline timeline"
    "nodes nodes map smnodes smnodes"
    "nodes nodes map smnodes smnodes";
  .bericht-block {
    grid-area: bericht;
  }
  .widget-block {
    grid-area: widget;
  }

  .social-media-nodes-block {
    grid-area: smnodes;
  }
  .social-media-graph-block {
    grid-area: smgraph;
  }

  .social-media-chart-block {
    grid-area: smchart;
  }
  .sparkline-block {
    grid-area: sparkline;
  }
  .word-cloud-block {
    grid-area: wordcloud;
  }

  .person-block {
    grid-area: person;
  }

  .map-block {
    grid-area: map;
  }

  .entities-nodes-block {
    grid-area: nodes;
  }

  .timeline-block {
    grid-area: timeline;
    grid-row: auto !important;
  }

  .news-block {
    grid-area: news;
  }
}

.rich-content {
  max-height: 450px;
  overflow-y: auto;

  a {
    color: rgb(var(--v-theme-primary)) !important;
    &:visited {
      color: rgb(var(--v-theme-primary)) !important;
    }
  }
  ul,
  ol {
    list-style-position: inside;
  }
  blockquote {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
  }
}
</style>
