import { useApiStore } from "@/common/store/api"
import type { Ref } from "vue"
import {
  APIReferenceable,
  ApiUrl,
  ObjectBase,
  PaginatedResult,
  SecretAccessPointData,
  SecretEntityData,
} from "@/common/lib/types"
import { trimEnd } from "lodash"
import { useTruthStore } from "@/common/store/truth"
import { computed } from "vue"
import { User, useUserStore } from "@/common/store/user"
import { DossierInfoDTO, LinkDTO } from "@/common/store/dossier"
import { AtomicOperator } from "@/common/lib/AtomicOperator"
import { ApiBase } from "@/common/lib/api"
import { ENDORSEMENT } from "@/common/lib/crypto/endorsement"
import { b64encode } from "@/common/lib/encoding"
import { useCryptoStore } from "@/common/store/crypto"

/*
 * byUrl, objects: reactive data
 * request(url) -> C? (background fetch)
 * async fetch(url) -> C (wait and replace)
 * async fetchAll() -> C[] (wait and replace)
 */

export type ObjectStoreOptions<
  C extends APIReferenceable,
  PC = PaginatedResult<Record<keyof C, any>>,
> = {
  hooks?: {
    fetchAllPost?: (items: PC) => Promise<PC>
  }
}

export function makeObjectStore<C extends APIReferenceable = ObjectBase>(
  apiPath: string,
  options?: ObjectStoreOptions<C>,
) {
  const api = useApiStore()
  const truth = useTruthStore()

  const basePath = api.base.normalizeUrl(`${trimEnd(apiPath, "/")}/`)
  const _truthEntries = truth.data.prefixEntries(basePath)
  const byUrl = computed(
    () => new Map<ApiUrl, C>(_truthEntries.value as [ApiUrl, C][]),
  )
  const objects = computed(() => Array.from(byUrl.value.values()))

  function request(url: ApiUrl): Ref<C | undefined> {
    return truth.data.get<C>(url)
  }

  async function fetch(url: ApiUrl): Promise<Ref<C>> {
    return await truth.fetch(url)
  }

  async function patch(url: ApiUrl, data: Partial<C>): Promise<Ref<C>> {
    return await truth.patch(url, data)
  }

  async function remove(url: ApiUrl) {
    await api.base.DELETE(url)
    truth.data.deleteWithPrejudice(url)
  }

  async function fetchAll(): Promise<Ref<C[]>> {
    let freshObjects =
      await api.GET<PaginatedResult<Record<keyof C, any>>>(basePath)
    if (options?.hooks?.fetchAllPost) {
      freshObjects = await options.hooks.fetchAllPost(freshObjects)
    }
    truth.data.clearPrefix(basePath)
    for (const object of freshObjects.results) {
      truth.data.set(object.url, object)
    }
    return objects
  }

  return {
    _truthEntries,

    byUrl,
    objects,

    request,
    fetch,
    patch,
    remove,
    fetchAll,
  }
}

export function opsHelpers() {
  const crypto = useCryptoStore()
  const api = useApiStore()
  const userStore = useUserStore()

  function _createAssociatedKeyOps(
    kid: string,
    key: Uint8Array,
    ...entities: (User | LinkDTO)[]
  ): AtomicOperator {
    const retval = new AtomicOperator(api.base as ApiBase)
    for (const akInfo of crypto.getEntityBoxes(...entities)) {
      retval.test(akInfo.obj).add(
        `/associatedkey/${kid}/${akInfo.entity_id}`,
        crypto.manager!.endorse(
          ENDORSEMENT.AssociatedKey,
          {
            wrapped_key: b64encode(akInfo.box.wrap(key)),
          },
          {
            kid,
            entity: akInfo.obj as User | LinkDTO,
          },
        ),
      )
    }
    return retval
  }

  function _mutateOps<T extends ObjectBase>(
    oldObj: T,
    newData: Partial<Record<keyof T, any>>,
    newEndorsement: Record<"endorsed_by" | "endorsement", any>,
  ) {
    const retval = new AtomicOperator(api.base as ApiBase)
    for (const [key, value] of Object.entries(newData)) {
      retval.replace(oldObj, key, value)
    }
    retval
      .replace(oldObj, "endorsed_by", newEndorsement.endorsed_by)
      .replace(oldObj, "endorsement", newEndorsement.endorsement)
    return retval
  }

  function _mutateDossierOps(
    dossier: DossierInfoDTO,
    newData: Partial<Record<keyof DossierInfoDTO, string>>,
  ): AtomicOperator {
    const newEndorsement = crypto.manager!.endorse(
      ENDORSEMENT.Dossier,
      dossier,
      {
        ...newData,
        endorsed_by: userStore.currentUser!,
      },
    )
    return _mutateOps(dossier, newData, newEndorsement)
  }

  function _mutateLinkOps(
    link: LinkDTO,
    newData: Partial<
      Record<keyof LinkDTO, string> & SecretEntityData & SecretAccessPointData
    >,
  ): AtomicOperator {
    const newEndorsement = crypto.manager!.endorse(ENDORSEMENT.Entity, link, {
      ...newData,
      endorsed_by: userStore.currentUser!,
    })
    return _mutateOps(link, newData, newEndorsement)
  }

  function _mutateUserOps(
    user: User,
    newData: Partial<User & SecretEntityData>,
  ): AtomicOperator {
    const newEndorsement = crypto.manager!.endorse(ENDORSEMENT.Entity, user, {
      ...newData,
      endorsed_by: userStore.currentUser!,
    })
    return _mutateOps(user, newData, newEndorsement)
  }

  return {
    _mutateLinkOps,
    _mutateDossierOps,
    _createAssociatedKeyOps,
    _mutateUserOps,
    _mutateOps,
  }
}
