import * as devalue from "devalue"
import Color from "color"
import {
  ChapterType,
  CreatedLink,
  DateString,
  DossierContents,
  EntityDataEntityIdentifier,
  SMART_CHAPTERS,
  SmartChapterTypes,
} from "@/common/store/dossier"
import config from "@/config"
import { trimEnd } from "lodash"
import { CreatedWorkstation } from "@/common/store/workstation"
import { computed, Ref, ref } from "vue"
import { createEventHook, watchArray } from "@vueuse/core"
import { DOMAIN_LIST } from "../data/domainList"
import { lookupDomain } from "@/common/data/domainList"

export function setsAreEqual<T>(set1: Set<T>, set2: Set<T>): boolean {
  if (set1.size !== set2.size) return false
  for (const item of set1) {
    if (!set2.has(item)) return false
  }
  return true
}

export function isSmartChapter(type: ChapterType): type is SmartChapterTypes {
  return SMART_CHAPTERS.includes(type as SmartChapterTypes)
}

// Helper function to concatenate parts into a single Uint8Array
export function concatenateParts(parts: (string | Uint8Array)[]): Uint8Array {
  // Calculate the total length for the resulting Uint8Array
  const totalLength =
    parts.reduce(
      (acc, part) =>
        acc +
        (typeof part === "string"
          ? new TextEncoder().encode(part).length
          : part.length),
      0,
    ) +
    (parts.length - 1)

  // Create the resulting Uint8Array
  const concatenatedBytes = new Uint8Array(totalLength)

  // Fill the resulting Uint8Array with the parts
  let offset = 0
  for (const part of parts) {
    if (offset != 0) {
      concatenatedBytes.set(new TextEncoder().encode(";"), offset)
      offset += 1
    }
    if (typeof part === "string") {
      const encodedPart = new TextEncoder().encode(part)
      concatenatedBytes.set(encodedPart, offset)
      offset += encodedPart.length
    } else {
      concatenatedBytes.set(part, offset)
      offset += part.length
    }
  }

  return concatenatedBytes
}

export function removeProxy<T>(state: T): T {
  return devalue.parse(devalue.stringify(state))
}

export const levelColors = {
  1: Color("#4d8").darken(0.3).hex(),
  2: Color("#ed0").darken(0.1).hex(),
  3: Color("#C66").darken(0.2).hex(),
}

export type PositionedElement = {
  content: {
    meta: {
      width: number
      pos: [number, number]
    }
  }
}

export function normalizeRows<T extends PositionedElement = PositionedElement>(
  intermediateList: T[],
): T[][] {
  intermediateList.sort(
    (a, b) =>
      a.content.meta.pos[0] - b.content.meta.pos[0] ||
      a.content.meta.pos[1] - b.content.meta.pos[1],
  )
  const newShape: T[][] = []
  let newRow: T[] | null = null
  let rowSum = 0
  for (const item of intermediateList) {
    if (
      !newRow ||
      item.content.meta.pos[0] !==
        newRow[newRow.length - 1]?.content.meta.pos[0]
    ) {
      newShape.push((newRow = []))
      rowSum = 0
    }
    if (rowSum + item.content.meta.width > 12) {
      newShape.push((newRow = []))
      rowSum = 0
    }
    newRow.push(item)
    rowSum += item.content.meta.width
  }

  return newShape.map((row, rowNum) =>
    row.map((item, index) => ({
      ...item,
      content: {
        ...item.content,
        meta: {
          ...item.content.meta,
          pos: [rowNum, index],
        },
      },
    })),
  )
}

type DataObject = {
  [key: string]: any
}

export function arrayToObjectArray<T extends DataObject>(
  data: any[][],
  fieldNames: (keyof T)[],
  defaults: Partial<T> | undefined = undefined,
): T[] {
  const result: T[] = []

  for (const row of data) {
    const obj: T = { ...defaults } as T
    fieldNames.forEach((title, index) => {
      obj[title] = row[index] as T[keyof T]
    })
    result.push(obj)
  }

  return result
}

export function fohUrl(link: CreatedLink) {
  const suffix = `/link/${link.slug}#${link.password}`
  return (
    trimEnd(config.fohUrl, "/") +
    suffix +
    (link.pin_state == "active" ? `,${link.pin}` : "")
  )
}

export function bohUrl(workstation: CreatedWorkstation) {
  const suffix = `/ws/${workstation.id}/#${workstation.password}`
  return new URL(suffix, window.location.toString()).toString()
}

export type DateStruct = {
  day: number | "X"
  month: number | "X"
  year: number
}

export function parseDate(value: string | undefined | null): DateStruct | null {
  const val = (value || "").trim()

  if (!val || !val.length) {
    return null
  }

  const formatRet = (x: any) =>
    ({
      day: parseInt(x.groups.day, 10) || "X",
      month: parseInt(x.groups.month, 10) || "X",
      year: parseInt(x.groups.year, 10),
    }) as DateStruct

  const match1 =
    /^(?<year>\d{4})(?:\s*[,./-]\s*(?<month>[01]\d|\d|1[012]|[xX]+)(?:\s*[,./-]\s*(?<day>\d|[012]\d|3[01]|[xX]+))?)?$/.exec(
      val,
    )
  if (match1) {
    return formatRet(match1)
  }

  const match2 =
    /^(?:(?:(?<day>\d|[012]\d|3[01]|[xX]+)\s*[,./-]\s*)?(?<month>\d|[01]\d|1[012]|[xX]+)\s*[,./-]\s*)?(?<year>\d{4})$/.exec(
      val,
    )
  if (match2) {
    return formatRet(match2)
  }

  return null
}

export function toIsoDate(d: DateStruct): DateString {
  return `${d.year.toString().padStart(4, "0")}-${
    d.month != "X" ? d.month.toString().padStart(2, "0") : "XX"
  }-${d.day != "X" ? d.day.toString().padStart(2, "0") : "XX"}` as DateString
}

export function toGermanDate(d: DateStruct): string {
  return `${d.day != "X" ? d.day.toString().padStart(2, "0") : "XX"}.${
    d.month != "X" ? d.month.toString().padStart(2, "0") : "XX"
  }.${d.year.toString().padStart(4, "0")}`
}

export function approximateDate(
  val: string | DateStruct | undefined,
): Date | undefined {
  if (!val) {
    return undefined
  }
  const d = typeof val === "string" ? parseDate(val) : val
  if (!d) {
    return undefined
  }
  return new Date(
    d.year,
    d.month == "X" ? 0 : d.month - 1,
    d.day == "X" ? 1 : d.day,
  )
}

export function withProtocol(value: string) {
  return /^http(s)?:\/\//.test(value) ? value : `https://${value}`
}

export const PLZ_RE = /(?<=\D|^)\d{5}(?=\D|$)/gi

export type EntityManager_RegisteredUser = string

export function useEntityManager(contents: Ref<DossierContents | null>) {
  const users = ref(
    {} as Record<EntityDataEntityIdentifier, Set<EntityManager_RegisteredUser>>,
  )

  const needEntityDataSection = createEventHook<EntityDataEntityIdentifier>()
  const disposeEntityDataSection = createEventHook<EntityDataEntityIdentifier>()
  const entities = ref(new Set<EntityDataEntityIdentifier>())

  for (const key of ["person", "company"] as ("person" | "company")[]) {
    watchArray(
      () => contents.value?.meta[key] ?? [],
      (newList, oldList, added, removed) => {
        for (const name of added) {
          entities.value.add(`${key}/${name}`)
          users.value[`${key}/${name}`] ??= new Set()
          needEntityDataSection.trigger(`${key}/${name}`)
        }
        for (const name of removed || []) {
          delete users.value[`${key}/${name}`]
          disposeEntityDataSection.trigger(`${key}/${name}`)
          entities.value.delete(`${key}/${name}`)
        }
      },
      {
        immediate: true,
      },
    )
  }

  const entitiesInUse = computed(() => {
    return Object.fromEntries(
      Object.entries(users.value).map(([entity, registrations]) => [
        entity,
        registrations.size > 0,
      ]),
    ) as { [n: EntityDataEntityIdentifier]: boolean }
  })

  function trackUsage(
    entity: EntityDataEntityIdentifier,
    user: EntityManager_RegisteredUser,
  ): void {
    ;(users.value[`${entity}`] ??= new Set()).add(user)
  }

  function untrackUsage(
    entity: EntityDataEntityIdentifier,
    user: EntityManager_RegisteredUser,
  ): void {
    users.value[`${entity}`]?.delete(user)
  }

  return {
    entities,
    trackUsage,
    untrackUsage,
    entitiesInUse,
    needEntityDataSection: needEntityDataSection.on,
    disposeEntityDataSection: disposeEntityDataSection.on,
  }
}

export const extractTitleNonSocialMedia = (
  inputString: string,
  domain: string,
) => {
  const regex = /(.{5,100})(\s\S\s.{5,50})/
  const match = inputString.match(regex)

  if (match) {
    const firstPart = match[1]
    const secondPart = match[2]

    if (secondPart.toLowerCase().includes(domain.toLowerCase())) {
      return firstPart.trim()
    }

    const lastDotIndex = domain.lastIndexOf(".")
    let domainParts = []
    if (lastDotIndex !== -1) {
      domainParts = [
        domain.substring(0, lastDotIndex),
        domain.substring(lastDotIndex + 1),
      ]
    } else {
      domainParts = [domain]
    }
    for (const part of domainParts) {
      if (secondPart.toLowerCase().includes(part.toLowerCase())) {
        return firstPart.trim()
      }
    }

    domainParts = domainParts[0].split(/[\s-]+/)
    for (const part of domainParts) {
      if (secondPart.toLowerCase().includes(part.toLowerCase())) {
        return firstPart.trim()
      }
    }
  }

  return inputString
}

export const extractHandle = (source: string, content: string) => {
  const page_title = content.match(
    /<meta\s*name="savepage-title"\s*content="(.*)">/,
  )
  if (page_title) {
    const handleMatch = page_title[1].match(
      /(?<=^|[^\/])(@[A-Za-z0-9_.]{3,25})/,
    )
    if (handleMatch) {
      return handleMatch[1]
    }
  }
}

// Helper function to set title and handle
export const setTitleAndHandle = (
  handle: string | null,
  title: string | null,
) => {
  if (handle) {
    if (title) {
      title = `${title} (${handle})`
    } else {
      title = `(${handle})`
    }
    return title
  }
}

// Helper function to set title and handle
export const extractTitle = (content: string, source: string) => {
  // Extract and process the title tag
  const titleTagMatch = content.match(/<title>([^<]*)<\/title>/)
  const splitChars = /[()\/|\-]/

  if (titleTagMatch) {
    const rawTitle = titleTagMatch[1]
    const titleParts = rawTitle
      .split(splitChars)
      .map((part) => part.trim())
      .filter((part) => part && isNaN(Number(part)))

    if (titleParts.length > 0) {
      return titleParts[0]
    }
  } else {
    if (source) {
      const parts = source.split("/").filter((part) => part.trim())
      return parts[parts.length - 1]
    }
  }
}

export async function extractWebInfo(file: File) {
  const retval = {} as {
    title?: string
    source?: string
    date?: string
    followers?: string
    established?: string
  }

  const fileReader = new FileReader()
  fileReader.readAsText(file)

  await new Promise((resolve) => (fileReader.onload = resolve))
  const content = fileReader.result as string

  const match2 = content.match(/<meta\s*name="savepage-url"\s*content="(.*)">/)
  if (match2) {
    retval.source = match2[1]
  }

  if (match2) {
    try {
      const domainEntry = lookupDomain(new URL(withProtocol(match2[1])))

      if (domainEntry) {
        if (domainEntry.type === "social") {
          if (domainEntry.extract) {
            let { followers, established, title } = domainEntry.extract({
              source: retval.source,
              content: content,
              possibleMatch: match2[1],
              domainName: domainEntry.name,
            })

            if (followers) {
              retval.followers = followers
            }

            if (established) {
              retval.established = established
            }

            if (title) {
              retval.title = title
            }
          }
        } else {
          const pageTitle = extractTitle(content, match2[1])
          const title = extractTitleNonSocialMedia(
            pageTitle || "",
            domainEntry.name,
          )

          if (title) {
            retval.title = title
          }
        }
      }
    } catch (e) {
      // Ignore
    }
  }

  let match3 = content.match(
    /<meta\s*name="savepage-pubdate"\s*content="(?!Unknown)(.*)">/,
  )
  if (!match3) {
    match3 = content.match(/<meta\s*name="savepage-date"\s*content="(.*)">/)
  }
  if (match3) {
    try {
      retval.date = new Date(match3[1]).toISOString().slice(0, 10)
    } catch (e) {
      if (!["Unknown"].includes(match3[1])) {
        console.log(e)
      }
    }
  }
  return retval
}

export function defang(value: any, path: string = ""): any {
  if (typeof value !== "object" || value === null || value === undefined) {
    return value
  } else {
    if (Array.isArray(value)) {
      return [...value.map((item) => defang(item, path + ".[]"))]
    } else {
      return Object.fromEntries(
        Object.entries(value).map(([key, value]) => {
          const innerPath = path + "." + key
          if (
            innerPath.endsWith(".sourceBlock") ||
            innerPath.endsWith(".files.[].body") ||
            innerPath.endsWith(".content.body")
          ) {
            value = "..."
          }
          return [key, defang(value, innerPath)]
        }),
      )
    }
  }
}

export const isValidUrl = (source: string) => {
  try {
    let url = source!.trim()
    if (url.toLowerCase().startsWith("www")) url = "https://" + url
    return new URL(url).toString()
  } catch (e) {
    return undefined
  }
}
export function useURLParser(url: string, isUrl: boolean = true) {
  const internalUrl = ref<URL | null>(null)
  const error = ref<string | null>(null)

  const isValid = computed(() => {
    try {
      let iUrl = url!.trim()
      if (iUrl.toLowerCase().startsWith("www")) iUrl = "https://" + iUrl
      return new URL(iUrl).toString()
    } catch (e) {
      return undefined
    }
  })

  const withProtocol = (value: string) => {
    if (!value.startsWith("//") && !/^http(s)?:\/\//.test(value)) {
      return `https://${value}`
    }
    return value
  }

  try {
    internalUrl.value = new URL(withProtocol(url))
  } catch (err) {
    error.value = (err as Error).message
  }

  const getURL = computed(() => {
    return internalUrl.value?.href!
  })

  const getHost = computed(() => {
    return internalUrl.value!.hostname?.toLowerCase()
  })

  const isListed = computed(() => {
    const domain: string = isUrl ? getHost.value : url + ".com"

    return DOMAIN_LIST.find((item) =>
      new RegExp(`^(?:[a-zA-Z0-9-]+\\.)*${item.regex}`).test(domain),
    )
  })

  const getDomainTitle = computed(() => {
    return isListed.value
      ? isListed.value!.name
      : getHost.value?.replace(/^www\./, "")
  })

  const getSocialMedia = computed(() => {
    if (isListed.value?.type === "social") return isListed.value
    return false
  })

  const getUserName = computed(() => {
    return getURL.value.split("/")[3]
  })

  const getColor = () => {
    if (isListed.value) return isListed.value.color
    return false
  }

  return {
    isValid,
    getURL,
    getHost,
    error,
    isListed,
    getSocialMedia,
    getDomainTitle,
    getColor,
    getUserName,
  }
}

export function useDrawerState() {
  const openDrawer = ref("")

  function setOpenDrawer(drawer: string) {
    openDrawer.value = drawer
  }

  function closeDrawer() {
    openDrawer.value = ""
  }

  return {
    openDrawer,
    setOpenDrawer,
    closeDrawer,
  }
}
