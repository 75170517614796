import { defineStore } from "pinia"
import {
  ApiUrl,
  B64UrlsafeData,
  SecretAccessPointData,
  SecretEntityData,
} from "@/common/lib/types"
import * as Sentry from "@sentry/browser"
import { computed, nextTick, ref, watch } from "vue"
import { BroadcastChannel as BroadcastChannelImpl } from "broadcast-channel"
import { b64decode, b64decodeUrlsafe, b64encode } from "@/common/lib/encoding"
import config from "@/config"
import log from "loglevel"
import { ApiBase, ApiFetchError } from "@/common/lib/api"
import { until, useStorage, useWindowFocus } from "@vueuse/core"
import { startAuthentication, startRegistration } from "@simplewebauthn/browser"
import {
  CryptoHelper,
  LoginResult,
  SecurityViolationError,
} from "@/common/lib/crypto"
import { removeProxy } from "@/common/lib/util"
import { useApiStore, wrapApiError } from "@/common/store/api"
import { startCase, trimEnd } from "lodash"
import {
  PublicKeyCredentialCreationOptionsJSON,
  PublicKeyCredentialRequestOptionsJSON,
} from "@simplewebauthn/types"

export type StoredAuth = {
  url: ApiUrl
  initialURL?: ApiUrl
  type: "workstation" | "link"
  name: string
  secret: B64UrlsafeData
  lastUsed: string
  provisional?: boolean
}

export type LoginState =
  | "unauthenticated"
  | "securing"
  | "completing"
  | "registeringFocusWait"
  | "authenticatingFocusWait"
  | "activatePin"
  | "requirePin"
  | "checkingPin"
  | "wrongPinError"
  | "registering"
  | "authenticating"
  | "failedError"
  | "completed"
  | "webauthnError"
  | "noauthError"
  | "cryptoError"
  | "otherError"
  | "rateLimitError"

export type SessionAuth = {
  entity: SecretEntityData
  accessPoint: SecretAccessPointData
  token: string
  credential?: string
  sessionKey: string
  exportKey: string
  serverPubkey: string
}

type SyncCommands = "query"

export type AuthenticationState =
  | "fresh"
  | "opaque_pending"
  | "authentication_pending"
  | "registration_pending"
  | "active"
interface LoginStep3Response {
  state: AuthenticationState
  entity?: Record<string, any> // FIXME more concrete
  access_point?: Record<string, any>
  credential?: string
}

/*
 * Handles all authorization and login state and synchronizes the login state between multiple tabs
 */
export const useAuthStore = defineStore(
  "auth",
  () => {
    const storedSecrets = useStorage<StoredAuth[]>(
      "storedAuthv1",
      [],
      localStorage,
    )
    const temporarySecrets = useStorage<Record<ApiUrl, B64UrlsafeData>>(
      "temporarySecretsv1",
      {},
      sessionStorage,
    )
    const sessionsByAccessPointUrl = useStorage<Record<ApiUrl, SessionAuth>>(
      "sessionAuthv1",
      {},
      sessionStorage,
    )
    const currentSessionAccessPointUrl = ref(null as ApiUrl | null)
    const state = ref(null as LoginState | null)
    const pin = ref(null as string | null)
    const pinState = ref(
      null as
        | null
        | "disabled"
        | "primed"
        | "requested"
        | "confirmed"
        | "entered"
        | "provided",
    )

    const syncChannel = new BroadcastChannelImpl<
      Record<ApiUrl, null | SessionAuth | SyncCommands>
    >("auth-updates-v1", { type: "native" })
    const queryWaitPromises = new Map<ApiUrl, () => void>()

    const storedByAccessPointUrl = computed(() => {
      const retval = {} as Record<ApiUrl, StoredAuth>
      for (const entry of storedSecrets.value) {
        retval[entry.url] = entry
        if (entry.initialURL) {
          retval[entry.initialURL] = entry
        }
      }
      return retval
    })
    const storedLRU = computed(() => {
      const retval = [...storedSecrets.value]
      retval.sort((a, b) =>
        a.lastUsed < b.lastUsed ? 1 : a.lastUsed > b.lastUsed ? -1 : 0,
      )
      return retval
    })
    const session = computed(() =>
      currentSessionAccessPointUrl.value
        ? sessionsByAccessPointUrl.value[currentSessionAccessPointUrl.value]
        : null,
    )

    async function handleLogin(): Promise<SessionAuth | null>
    async function handleLogin(
      accessPointUrl: ApiUrl,
    ): Promise<SessionAuth | null>
    async function handleLogin(
      accessPointUrl: ApiUrl,
      secret: B64UrlsafeData,
    ): Promise<SessionAuth | null>
    async function handleLogin(
      accessPointUrl?: ApiUrl,
      secret?: B64UrlsafeData,
    ): Promise<SessionAuth | null> {
      log.debug("handleLogin", { accessPointUrl, secret })
      if (!accessPointUrl) {
        // Try to find out most recent applicable access point
        if (storedLRU.value?.length > 0) {
          accessPointUrl = storedLRU.value[0].url
        } else {
          accessPointUrl = await tryRestoreAuthv0()
          if (!accessPointUrl) {
            // Cannot determine access point for implicit login
            state.value = "noauthError"
            return null
          }
        }
      }

      const initialURL = accessPointUrl
      // Try to look up and maybe map the URL
      if (storedByAccessPointUrl.value[initialURL]) {
        accessPointUrl = storedByAccessPointUrl.value[initialURL].url
      }
      log.trace("looked up and mapped url", { initialURL, accessPointUrl })

      let loadedSession = sessionsByAccessPointUrl.value[accessPointUrl]
      log.debug({ loadedSession })
      if (!loadedSession) {
        // Ask if other tabs have the session
        loadedSession = await querySession(accessPointUrl)
      }
      log.debug({ loadedSession })
      if (loadedSession) {
        // Check if session is still alive
        if (await checkSession(loadedSession)) {
          currentSessionAccessPointUrl.value = accessPointUrl
          state.value = "completed"
          return loadedSession
        } else {
          // Make sure everyone knows this session is not alive anymore
          // FIXME Go to logout
          await destroySession(accessPointUrl)
          state.value = "unauthenticated"
        }
      }

      // Need to create a new session
      if (!secret) {
        secret = storedByAccessPointUrl.value[accessPointUrl]?.secret
      }
      if (!secret) {
        secret = tryRecoverSecretv0(accessPointUrl)
      }
      if (!secret) {
        secret = temporarySecrets.value[accessPointUrl]
      }
      log.debug({ secret })
      if (!secret) {
        // Don't have necessary secret
        state.value = "noauthError"
        return null
      }

      temporarySecrets.value[accessPointUrl] = secret

      const newSession = await innerLogin(initialURL, secret)
      if (!newSession) {
        // state will have been set by innerLogin
        // FIXME Handle failure, clear stored secret?
        return null
      }

      storeSecret(newSession, secret, initialURL)
      await storeAndNotifySession(newSession)
      delete temporarySecrets.value[accessPointUrl]
      currentSessionAccessPointUrl.value = newSession.accessPoint.url
      state.value = "completed"

      return newSession
    }

    async function tryRestoreAuthv0(): Promise<ApiUrl | undefined> {
      const lastTarget = localStorage.getItem("lastTarget")
      if (!lastTarget) {
        return
      }
      const data: Map<string, string> = new Map()
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i)
        if (!key) {
          continue
        }
        if (
          (key.startsWith("link/") || key.startsWith("workstation/")) &&
          key.endsWith(".pw")
        ) {
          data.set(key.split(".")[0], localStorage.getItem(key)!)
        }
      }
      if (!data.has(lastTarget)) {
        return
      }
      const apiBase = new ApiBase()
      for (const [key, secret] of data.entries()) {
        const [type, id] = key.split("/", 1)
        storedSecrets.value.push({
          url: apiBase.normalizeUrl(trimEnd(key, "/") + "/"),
          name: `${startCase(type)} object (${id})`,
          secret,
          type: type as "workstation" | "link",
          lastUsed:
            key === lastTarget
              ? new Date().toISOString()
              : new Date(0).toISOString(),
        })
      }
      // Wait to settle watchers
      await new Promise((resolve) => window.setTimeout(resolve, 200))
      await nextTick()
      return apiBase.normalizeUrl(trimEnd(lastTarget, "/") + "/")
    }

    function tryRecoverSecretv0(accessPointUrl: ApiUrl) {
      const key =
        trimEnd(accessPointUrl, "/").split("/").slice(-2).join("/") + ".pw"
      const secret = localStorage.getItem(key)
      if (secret) {
        return secret
      }
    }

    async function querySession(accessPointUrl: ApiUrl) {
      const p = new Promise<void>((resolve) => {
        log.trace("running query session", { accessPointUrl })
        queryWaitPromises.set(accessPointUrl, resolve)
      }).finally(() => {
        log.trace("done query session", { accessPointUrl })
        queryWaitPromises.delete(accessPointUrl)
      })
      await syncChannel.postMessage({ [accessPointUrl]: "query" })
      // Wait for responses, but not too long
      setTimeout(() => {
        log.debug("timeout exceeded", { accessPointUrl })
        if (queryWaitPromises.has(accessPointUrl)) {
          queryWaitPromises.get(accessPointUrl)!()
        }
      }, 2000)
      await p
      return sessionsByAccessPointUrl.value[accessPointUrl]
    }
    syncChannel.addEventListener("message", (message) => {
      log.trace("received message", { message })
      for (const [key, session] of Object.entries(message)) {
        if (session !== null && typeof session !== "string") {
          if (queryWaitPromises.has(key)) {
            queryWaitPromises.get(key)!()
          }
        }
      }
    })

    async function checkSession(session: SessionAuth) {
      try {
        // FIXME Maybe move into api store somehow
        const response = await fetch(config.backend.api + "user/me/", {
          method: "GET",
          headers: {
            Authorization: `Token ${session.token}`,
          },
        })
        return response.status == 200
      } catch (e) {
        return false
      }
    }

    async function destroySession(
      accessPointUrl: ApiUrl,
      _options = { notify: true },
    ) {
      if (currentSessionAccessPointUrl.value === accessPointUrl) {
        // FIXME Go to logged out
        currentSessionAccessPointUrl.value = null
      }
      delete sessionsByAccessPointUrl.value[accessPointUrl]
      if (_options.notify) {
        await syncChannel.postMessage({ [accessPointUrl]: null })
      }
    }

    type LoginCompleteResponse = {
      token: string
    } & (
      | {
          state: "authentication_pending"
          entity: ApiUrl
          options: PublicKeyCredentialRequestOptionsJSON
        }
      | {
          state: "registration_pending"
          entity: ApiUrl
          options: PublicKeyCredentialCreationOptionsJSON
        }
      | {
          state: "pin_pending"
          entity: ApiUrl
          options: null
        }
      | ({
          state: "active"
        } & LoginStep3Response)
    )

    async function innerLoginOpaque(
      api: ApiBase,
      accessPointUrl: ApiUrl,
      secret: string,
    ) {
      const crypto = new CryptoHelper()
      try {
        state.value = "securing"
        const ke1 = crypto.loginStep1(b64decodeUrlsafe(secret))

        const step1Response = await api.POST<Record<string, any>>(
          new URL("login/", accessPointUrl).toString(),
          ke1,
        )

        state.value = "completing"

        const loginResult = crypto.loginStep2(b64decode(step1Response.ke2))

        const completeResponse = await api.POST<LoginCompleteResponse>(
          step1Response.url + "complete/",
          loginResult.ke3,
        )

        return {
          loginBaseUrl: step1Response.url,
          loginResult,
          completeResponse,
        }
      } finally {
        crypto.cleanup()
      }
    }

    async function innerLoginWebauthn(
      api: ApiBase,
      loginBaseUrl: ApiUrl,
      completeResponse: LoginCompleteResponse & {
        state: "registration_pending" | "authentication_pending"
      },
    ) {
      const registrationPending =
        completeResponse.state === "registration_pending"

      state.value = `${
        registrationPending ? "registering" : "authenticating"
      }FocusWait`
      await until(useWindowFocus()).toBe(true)
      state.value = registrationPending ? "registering" : "authenticating"

      let webAuthnResponse = null
      try {
        webAuthnResponse = registrationPending
          ? await startRegistration(completeResponse.options)
          : await startAuthentication(completeResponse.options)
      } catch (e) {
        // Webauthn call failed
        if (state.value == "registering") {
          // Offer PIN fallback
          return { step3Response: null, offerPinActivation: true }
        } else {
          // Webauthn needed but failed
          state.value = "webauthnError"
          return { step3Response: null, offerPinActivation: false }
        }
      }

      const step3Response = await wrapApiError(
        api.POST<LoginStep3Response>(
          loginBaseUrl + (registrationPending ? "register/" : "authenticate/"),
          { response: webAuthnResponse },
        ),
      )

      return { step3Response, offerPinActivation: false }
    }

    async function innerLoginAuthenticatePin(
      api: ApiBase,
      loginBaseUrl: ApiUrl,
    ) {
      state.value = "checkingPin"

      try {
        return await api.POST<LoginStep3Response>(
          loginBaseUrl + "authenticate/",
          {
            pin: pin.value,
          },
        )
      } catch (e) {
        if (e instanceof ApiFetchError && e.status == 403) {
          state.value = "wrongPinError"
          return null
        }
        throw e
      }
    }

    async function innerLoginActivatePin(api: ApiBase, loginBaseUrl: ApiUrl) {
      pin.value = null
      pinState.value = "primed"
      state.value = "activatePin"

      await until(pinState).toMatch((v) => v === "requested" || v === null)

      if (!pinState.value) {
        return null
      }

      // Fetch and reveal pin

      const pinResponse = await wrapApiError<{ pin: string }>(
        api.POST(loginBaseUrl + "reveal_pin/", {}),
      )
      pin.value = pinResponse.pin

      // Wait for PIN copy to be confirmed
      await until(pinState).toBe("confirmed")

      // Wait time for user to see message
      // This is ugly
      await new Promise((resolve) => setTimeout(resolve, 5000))

      // Return common pin authenticate
      return innerLoginAuthenticatePin(api, loginBaseUrl)
    }

    async function innerLoginRequirePin(api: ApiBase, loginBaseUrl: ApiUrl) {
      if (pinState.value != "provided") {
        pin.value = null
        state.value = "requirePin"

        // wait for ready flag
        await until(pinState).toBe("entered")
      }

      // Return common pin authenticate
      return innerLoginAuthenticatePin(api, loginBaseUrl)
    }

    async function innerLogin(
      accessPointUrl: ApiUrl,
      secret: B64UrlsafeData,
    ): Promise<SessionAuth | null> {
      const api = new ApiBase()

      let token: string | null = null
      let step3Response: LoginStep3Response | null = null

      try {
        const { loginBaseUrl, completeResponse, loginResult } =
          await innerLoginOpaque(api, accessPointUrl, secret)

        // Maybe not fully logged in yet
        token = completeResponse.token
        api.localHeaders = { Authorization: `Token ${token}` }

        if (
          completeResponse.state == "registration_pending" ||
          completeResponse.state == "authentication_pending"
        ) {
          let offerPinActivation = false
          ;({ step3Response, offerPinActivation } = await innerLoginWebauthn(
            api,
            loginBaseUrl,
            completeResponse,
          ))
          if (offerPinActivation && pinState.value != "disabled") {
            step3Response = await innerLoginActivatePin(api, loginBaseUrl)
          }
        } else if (completeResponse.state == "pin_pending") {
          step3Response = await innerLoginRequirePin(api, loginBaseUrl)
        } else if (completeResponse.state == "active") {
          step3Response = completeResponse as LoginStep3Response
        } else {
          // noinspection ExceptionCaughtLocallyJS
          throw new Error(
            // @ts-ignore
            `Unexpected state in login: ${completeResponse.state}`,
          )
        }

        if (
          !step3Response ||
          step3Response.state != "active" ||
          !step3Response.access_point ||
          !step3Response.entity
        ) {
          Sentry.captureMessage(`Error in login: failed`, "fatal")
          if (!state.value || !state.value.endsWith("Error")) {
            state.value = "failedError"
          }
        } else if (token && step3Response && loginResult) {
          return {
            token,
            entity: step3Response.entity! as SecretEntityData,
            accessPoint: step3Response.access_point! as SecretAccessPointData,
            sessionKey: b64encode(loginResult.sessionKey),
            exportKey: b64encode(loginResult.exportKey),
            serverPubkey: b64encode(loginResult.serverPubkey),
          }
        }
      } catch (e) {
        console.error(e)
        if (e instanceof SecurityViolationError) {
          state.value = "cryptoError"
        } else {
          if (e instanceof ApiFetchError && e.status == 429) {
            state.value = "rateLimitError"
          } else if (state.value && !state.value.endsWith("Error")) {
            state.value = "otherError"
          }
        }
        Sentry.captureMessage(`Error in login: ${state.value}`, "fatal")
      }

      return null
    }

    function storeSecret(
      session: SessionAuth,
      secret: B64UrlsafeData,
      initialURL?: ApiUrl,
    ) {
      const entry = storedSecrets.value.find(
        (item) => item.url === session.accessPoint.url,
      )
      if (entry) {
        entry.secret = secret
        entry.name = session.accessPoint.display_name
        entry.lastUsed = new Date().toISOString()
      } else {
        storedSecrets.value.push({
          url: session.accessPoint.url,
          name: session.accessPoint.display_name,
          // FIXME is type relevant, is it used anywhere? This is just a heuristic
          type:
            session.accessPoint.id == session.entity.id
              ? "link"
              : "workstation",
          initialURL,
          secret,
          lastUsed: new Date().toISOString(),
        })
      }
    }

    async function storeAndNotifySession(session: SessionAuth) {
      sessionsByAccessPointUrl.value[session.accessPoint.url] = session
      await syncChannel.postMessage({
        [session.accessPoint.url]: removeProxy(session),
      })
    }

    syncChannel.addEventListener("message", async (message) => {
      for (const [key, session] of Object.entries(message)) {
        if (session === null) {
          await destroySession(key, { notify: false })
        } else if (typeof session === "string") {
          if (session === "query") {
            // Someone asks us for the session, send it
            if (sessionsByAccessPointUrl.value[key]) {
              await syncChannel.postMessage({
                [key]: removeProxy(sessionsByAccessPointUrl.value[key]),
              })
            }
          }
        } else {
          sessionsByAccessPointUrl.value[session.accessPoint.url] = session
        }
      }
    })

    async function lockWorkstation() {}

    return {
      sessionsByAccessPointUrl,
      currentSessionAccessPointUrl,
      storedSecrets,
      state,
      pin,
      pinState,

      storedByAccessPointUrl,
      session,

      handleLogin,
      lockWorkstation,
    }
  },
  {},
)
