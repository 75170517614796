import { defineStore } from "pinia"
import { makeObjectStore, opsHelpers } from "@/common/store/_helper"
import { AssociatedKey, useCryptoStore } from "@/common/store/crypto"
import { asyncComputed, until, useSorted, watchArray } from "@vueuse/core"
import { computed, reactive, ref, Ref } from "vue"
import { difference, keyBy, omit, pick } from "lodash"
import {
  BlockInfoDTO,
  BlockType,
  ChapterType,
  CreatedLink,
  DecryptedBlock,
  DossierContents,
  DossierContentSection,
  DossierInfoDTO,
  DossierLevel,
  DossierManifestDTO,
  DossierManifestDTOv1,
  DossierMetaDTO,
  DossierMetaType,
  DossierOverview,
  DossierView,
  DossierRetrieveData,
  EventInfo,
  ImageInfo,
  isDossierManifestV1,
  LABELED_FILE_SECTION_TYPES,
  LabeledFile,
  LabeledFileDTO,
  LabeledFileSectionTypes,
  LayoutDesign,
  LinkDTO,
  LocationInfo,
  ManifestBlockDTOv0,
  ManifestBlockDTOv1,
  MetricInfo,
  RemoteFile,
  ReportInfo,
  ReverseMapWithFiles,
  Section,
  SocialMediaTypeX,
  UserDossierRole,
  UserMapping,
  WebSnapshotInfo,
  WITH_FILES_SECTION_TYPES,
  WithFilesSectionTypes,
  EntityDataEntityIdentifier,
  EntityDataInfo,
  ManifestBlockDTO,
  LayoutType,
  ExternalWidgetEntry,
  PinState,
} from "./types"
import {
  APIReferenceable,
  ApiUrl,
  EncryptedObj,
  NonUndefined,
} from "@/common/lib/types"
import { useApiStore } from "@/common/store/api"
import { User, useUserStore } from "@/common/store/user"
import { AtomicOperator, TestableObject } from "@/common/lib/AtomicOperator"
import { ENDORSEMENT } from "@/common/lib/crypto/endorsement"
import { ApiBase } from "@/common/lib/api"
import { useTruthStore } from "@/common/store/truth"
import { EntityPrivateBox, SecretBoxJson } from "@/common/lib/crypto"
import nacl from "@/common/lib/nacl"
import { b64decode, b64encode, b64encodeUrlsafe } from "@/common/lib/encoding"
import { hash_blake2b } from "@dossier-direct/crypto"
import { normalizeRows, PositionedElement } from "@/common/lib/util"
import { generateRandomString } from "@/common/lib/crypto/util"
import {
  collectEmailAddresses,
  collectEventsFromChronology,
  collectEventsFromFiles,
  collectEventsFromSocialMedia,
  collectImages,
  collectLocationsFromEntities,
  collectLocationsFromPLZ,
  collectMetricData,
  collectReports,
  collectSocialMediaData,
  collectWidgetsData,
  extractEntityInformation,
} from "@/common/store/dossier/extract_data"
import { DEFAULT_SECTIONS } from "@/common/store/dossier/default"
import {
  migrateArchiveBlockEnsureID,
  migrateDossierContentsArchiveBlock,
  migrateDossierContentsBaseLayout,
} from "@/common/store/dossier/migration"
import { useProgress } from "@/common/lib/progress"

export const useDossierStore = defineStore("dossier", () => {
  const {
    objects: _dossierDataRaw,
    request: _requestDossier,
    fetch: _fetchDossier,
    fetchAll: fetchDossiers,
  } = makeObjectStore<DossierInfoDTO>("dossier/", {
    hooks: {
      async fetchAllPost(items) {
        const missingKeyIds = items.results
          .map((dossier) => dossier.encryption_kid)
          .filter(
            (keyId) => !truthStore.data.hasRightNow(`associatedkey/${keyId}/`),
          )
        if (missingKeyIds.length) {
          console.log("Prefetching", missingKeyIds)
          await truthStore.prefetch(
            `associatedkey/_multi/?kid=${missingKeyIds.join(",")}`,
          )
        }
        return items
      },
    },
  })
  const { _mutateLinkOps, _mutateDossierOps, _createAssociatedKeyOps } =
    opsHelpers()

  const crypto = useCryptoStore()
  const api = useApiStore()
  const userStore = useUserStore()
  const truthStore = useTruthStore()

  const linksByDossierUrl = reactive(new Map<ApiUrl, LinkDTO[]>())

  const dossiers = computed(() =>
    _dossierDataRaw.value.filter((item) => !!item.url),
  )
  const byUrl = computed(
    () =>
      new Map<ApiUrl, DossierInfoDTO>(
        dossiers.value.map((item) => [item.url, item]),
      ),
  )

  const currentLayout: Ref<LayoutType> = ref("viel")

  const dossiersSorted = useSorted<DossierInfoDTO>(dossiers as any, (a, b) =>
    a.created_at > b.created_at ? -1 : 1,
  )

  const loading = useProgress<
    "retrieve" | "setup" | "blockDecrypt" | "loadSection"
  >()

  const printMode = ref<boolean>(false)

  function getDossierOverview(url: ApiUrl) {
    return computed(() => {
      const dossierInfoDto = _requestDossier(url)
      if (!dossierInfoDto.value) {
        return null
      }
      const dossierInfo: DossierOverview = {
        ...pick(dossierInfoDto.value, [
          "id",
          "url",
          "code_name",
          "users",
          "cas_hash",
          "secret_cas_hash",
          "encryption_kid",
        ]),
        created_at: new Date(dossierInfoDto.value.created_at),
        usersByRole: {} as any,
      }

      if (crypto.ready) {
        const keyBox = crypto.getSecretBox(dossierInfoDto.value.encryption_kid)
        if (keyBox.value) {
          const manifest = keyBox.value.decryptJson<DossierManifestDTO>(
            dossierInfoDto.value.manifest,
          )
          dossierInfo.level = manifest.level
          dossierInfo.lastChange = new Date(manifest.lastChange)

          if (isDossierManifestV1(manifest)) {
            dossierInfo.display = manifest.display
            currentLayout.value = manifest?.display?.layout || "viel"
            // Try to load meta block opportunistically
            if (manifest.metaBlockId) {
              const metaBlockInfo = manifest.blocks.find(
                (item) => item.id === manifest.metaBlockId,
              )
              if (metaBlockInfo) {
                dossierInfo.meta = asyncComputed(async () => {
                  const mb = await decryptBlock(metaBlockInfo)
                  const body = (await mb.bodyLoader()) as Uint8Array
                  if (body) {
                    return JSON.parse(new TextDecoder().decode(body))
                  }
                }, null)
              }
            }
          } else {
            dossierInfo.display = { layout: LayoutDesign.Layout1 }
          }
        }
      }

      for (const item of dossierInfoDto.value.users) {
        const user = userStore.requestUser(item.user)
        if (user.value) {
          dossierInfo.usersByRole[item.role] = user.value
        }
      }

      return dossierInfo
    })
  }

  function filterRemoteFile(rf: RemoteFile, content: any): any[] {
    const retval = [content]
    if (rf.sourceBlock?.head.meta.mime == "text/html") {
      retval.unshift(
        `<meta http-equiv="Content-Security-Policy" content="default-src 'self' 'unsafe-inline' data:">`,
      )
    }
    return retval
  }

  async function loadRemoteFile(rf: RemoteFile) {
    if (rf.body !== null) {
      return rf.body
    }
    if (!rf.sourceBlock) {
      throw new Error("Cannot load remote file with no source")
    }
    if (!rf.sourceBlock.bodyLoader) {
      console.debug("Offending block", { head: rf.sourceBlock?.head })
      throw new Error(
        `Cannot load remote file with no body loader: ${rf.sourceBlock?.id}`,
      )
    }
    rf.body = new File(
      filterRemoteFile(rf, await rf.sourceBlock.bodyLoader()),
      rf.sourceBlock.head.meta.name,
      { type: rf.sourceBlock.head.meta.mime },
    )
    return rf.body
  }

  function prepareFileAttachments(
    blocks: Record<string, DecryptedBlock>,
    files: LabeledFileDTO[],
  ): LabeledFile[] {
    return files.map((item) => ({
      block: item[0],
      label: item[1],
      source: item[2],
      date: item[3],
      content: {
        sourceBlock: blocks[item[0]],
        meta: blocks[item[0]].head.meta,
        body: null,
      },
    }))
  }

  async function loadSectionv0<T extends ChapterType>(
    blockInfo: ManifestBlockDTOv0,
    blocks: Record<string, DecryptedBlock>,
    blockId: string,
  ): Promise<Section<T> | null> {
    if (["File"].includes(blockInfo.type)) {
      return null
    }

    const block = blocks[blockId]
    await block.bodyLoader()

    const retval: Partial<Section<T>> = {
      type: blockInfo.type as T,
      meta: {
        ...block.head.meta,
        pos: [
          Math.floor(blockInfo.chapterIndex / 12),
          blockInfo.chapterIndex % 12,
        ],
      },
    }
    if (["Text"].includes(blockInfo.type)) {
      ;(retval as Section<"Text">).body = new TextDecoder().decode(block.body)
    } else if (LABELED_FILE_SECTION_TYPES.includes(blockInfo.type)) {
      const files = JSON.parse(new TextDecoder().decode(block.body))
      ;(retval as Section<LabeledFileSectionTypes>).body =
        prepareFileAttachments(blocks, files)
    } else {
      retval.body = JSON.parse(new TextDecoder().decode(block.body))
    }
    return retval as Section<T>
  }

  async function loadSectionv1<T extends ChapterType>(
    blockInfo: ManifestBlockDTOv1,
    blocks: Record<string, DecryptedBlock>,
    blockId: string,
  ): Promise<Section<T> | null> {
    const block = blocks[blockId]

    const type = block.head.type!
    if (["File", "DossierMeta"].includes(type)) {
      return null
    }

    await block.bodyLoader()

    const retval = pick(block.head, ["meta", "type"])
    if (["Text"].includes(type)) {
      ;(retval as Section<"Text">).body = new TextDecoder().decode(block.body)
    } else if (LABELED_FILE_SECTION_TYPES.includes(type)) {
      const files = JSON.parse(new TextDecoder().decode(block.body))
      ;(retval as Section<LabeledFileSectionTypes>).body =
        prepareFileAttachments(blocks, files)
    } else if (WITH_FILES_SECTION_TYPES.includes(type)) {
      const data = JSON.parse(
        new TextDecoder().decode(block.body),
      ) as ReverseMapWithFiles<
        NonUndefined<Section<WithFilesSectionTypes>["body"]>
      >
      ;(retval as Section<WithFilesSectionTypes>).body = {
        ...omit(data, ["entries"]),
        entries: data!.entries.map((entry) => ({
          ...omit(entry, ["files"]),
          files: entry.files.map((file) => ({
            sourceBlock: blocks[file],
            meta: blocks[file].head.meta,
            body: null,
          })),
        })),
      }
    } else {
      ;(retval as Section<ChapterType>).body = JSON.parse(
        new TextDecoder().decode(block.body),
      )
    }
    return retval as Section<T>
  }

  async function loadSectionsFromManifest(
    manifest: DossierManifestDTO,
    blocks: Record<string, DecryptedBlock>,
  ) {
    const version =
      (Object.hasOwn(manifest, "version") && manifest?.version) || 0

    const promises = manifest.blocks
      .map(async (item) => {
        const section =
          version == 0
            ? await loadSectionv0(item as ManifestBlockDTOv0, blocks, item.id)
            : version === 1
            ? await loadSectionv1(item as ManifestBlockDTOv1, blocks, item.id)
            : null
        loading.progress("loadSection", 1)
        if (section) {
          return {
            content: section,
            dirty: false,
            sourceBlockId: item.id,
          }
        } else {
          return null
        }
      })
      .filter((p) => p !== null)

    return (await Promise.all(promises)).filter(
      (p) => !!p,
    ) as DossierContentSection[]
  }

  async function decryptBlock(
    manifestBlock: ManifestBlockDTO,
  ): Promise<DecryptedBlock> {
    const url = api.base.normalizeUrl(`block/${manifestBlock.id}/`)
    const blockInfo = await until(
      await truthStore.fetch<BlockInfoDTO>(url),
    ).toBeTruthy()
    const secretBox = new SecretBoxJson(b64decode(manifestBlock.key))
    const headData = b64decode(blockInfo.head)
    if (manifestBlock.headHash !== hash_blake2b(headData)) {
      throw new Error("Head hash mismatch")
    }
    const head = secretBox.decryptJson<Record<string, any>>(headData)

    const retval = {
      id: manifestBlock.id,
      url,
      head,
      body: null as any,
      bodyLoader: async () => {
        if (retval.body) {
          return retval.body
        }
        const encryptedBody = await api.base.GET<Uint8Array>(`${url}body/`)
        if (head.bodyHash !== hash_blake2b(encryptedBody)) {
          throw new Error("Body hash mismatch")
        }
        return (retval.body = secretBox.decrypt(encryptedBody))
      },
    }
    return retval
  }

  async function decryptBlocks(manifest: DossierManifestDTO) {
    const decryptedBlocks: DecryptedBlock[] = await Promise.all(
      manifest.blocks.map(async (manifestBlock) => {
        const retval = await decryptBlock(manifestBlock)
        loading.progress("blockDecrypt", 1)
        return retval
      }),
    )

    return keyBy(decryptedBlocks, "id")
  }

  async function getDossierContents(
    url: ApiUrl,
  ): Promise<DossierContents | null> {
    loading.reset()
    loading.advise("retrieve", 1, 30)
    loading.advise("setup", 1, 30)
    loading.advise("blockDecrypt", 2)
    loading.queue("setup", 5)
    loading.queue("blockDecrypt", 10, true)
    loading.queue("loadSection", 10, true)

    const retrieveUrl = url + "retrieve/"
    const retrieveResult = await loading.call("retrieve", async () =>
      api.GET<DossierRetrieveData>(retrieveUrl),
    )
    loading.progress("setup", 1)
    if (!retrieveResult) {
      return null
    }
    truthStore.update([
      retrieveResult.dossier,
      ...retrieveResult.links,
      ...retrieveResult.blocks,
      retrieveResult.meta,
    ])
    linksByDossierUrl.set(retrieveResult.dossier.url, retrieveResult.links)
    loading.progress("setup", 1)

    const dossierInfoDto = _requestDossier(url).value
    if (!dossierInfoDto) {
      return null
    }
    loading.progress("setup", 1)

    await until(computed(() => crypto.ready)).toBeTruthy()
    loading.progress("setup", 1)

    const keyBox = crypto.getSecretBox(dossierInfoDto.encryption_kid)
    await until(computed(() => keyBox.value)).toBeTruthy()
    if (!keyBox.value) {
      return null
    }
    loading.progress("setup", 1)

    const manifest = keyBox.value.decryptJson<DossierManifestDTO>(
      dossierInfoDto.manifest,
    )
    loading.queue("blockDecrypt", manifest.blocks.length)
    loading.queue("loadSection", manifest.blocks.length)

    const blocks = await decryptBlocks(manifest)

    let meta: DossierMetaType = {
      title: "",
      dirty: true,
      sourceBlockId: undefined,
    }
    if (isDossierManifestV1(manifest) && manifest.metaBlockId) {
      meta = {
        ...(JSON.parse(
          new TextDecoder().decode(
            await blocks[manifest.metaBlockId].bodyLoader(),
          ),
        ) as DossierMetaDTO),
        dirty: false,
        sourceBlockId: manifest.metaBlockId,
      }
    }

    const sections = await loadSectionsFromManifest(manifest, blocks)
    let retval = {
      url,
      rows: normalizeRows<PositionedElement & DossierContentSection>(sections),
      rawBlocks: blocks,
      meta,
    } as DossierContents

    retval = migrateDossierContentsArchiveBlock(retval)
    retval = migrateDossierContentsBaseLayout(retval)
    retval = migrateArchiveBlockEnsureID(retval)

    return retval
  }

  async function loadDossierPreview(
    overview: DossierOverview,
    contents: DossierContents,
  ): Promise<DossierView> {
    const meta = pick(overview, [
      "created_at",
      "code_name",
      "level",
      "url",
      "lastChange",
    ]) as DossierView["meta"]
    const retval: DossierView = {
      meta,
      emails: [] as string[],
      events: [] as EventInfo[],
      social_media: [] as SocialMediaTypeX[],
      images: [] as ImageInfo[],
      locations: [] as LocationInfo[],
      reports: [] as ReportInfo[],
      web_snapshots: [] as WebSnapshotInfo[],
      widgets: [] as ExternalWidgetEntry[],
      metrics: [] as MetricInfo[],
      entities: {} as Record<EntityDataEntityIdentifier, EntityDataInfo>,
    }

    if (contents.meta?.title) {
      retval.meta.title = contents.meta.title
    }

    // Location information from text serach
    collectLocationsFromPLZ(retval, contents)

    // Find and load all images, image events
    await collectImages(retval, contents)

    // Extract all entity information, prerequisite: images loaded
    extractEntityInformation(retval, contents)

    // Find all Emails, prerequisites entity data
    collectEmailAddresses(retval, contents)

    // Location from entities, prerequisite: entity data
    collectLocationsFromEntities(retval, contents)

    // Find events from chronology
    collectEventsFromChronology(retval, contents)

    // Reports
    collectReports(retval, contents)

    // Events from social media
    collectEventsFromSocialMedia(retval, contents)

    // Events from files, press clippings
    collectEventsFromFiles(retval, contents)

    // Social Media
    collectSocialMediaData(retval, contents)

    // Find and handle metric information/charts
    collectMetricData(retval, contents)

    // Widgets Data
    collectWidgetsData(retval, contents)

    // Remove events that have unparseable date
    retval.events = retval.events.filter(
      (event) => !isNaN(event.date?.getTime()),
    )

    // Sort events
    retval.events.sort((a, b) => a.date.getTime() - b.date.getTime())
    retval.web_snapshots.sort((a, b) =>
      a.date && b.date ? b.date.getTime() - a.date.getTime() : 0,
    )

    return retval
  }

  async function createBlockForHeadAndBody(
    head: Record<string, any> & { type: BlockType },
    body?: Uint8Array,
  ): Promise<{ manifestEntry: ManifestBlockDTOv1; block: BlockInfoDTO }> {
    let block = null as BlockInfoDTO | null
    let repeatCount = 10
    let key = null as Uint8Array | null
    let encryptedHeadData = null as string | null

    while (repeatCount > 0 && !block) {
      try {
        repeatCount--
        key = nacl.random_bytes(32)
        const blockBox = new SecretBoxJson(key)

        let encryptedBodyData: Uint8Array | null = null
        if (body !== undefined) {
          encryptedBodyData = blockBox.encrypt(body)
        }
        const encryptedBodyHash =
          encryptedBodyData && hash_blake2b(encryptedBodyData)

        const headData = {
          ...head,
          bodyHash: encryptedBodyHash,
        }

        encryptedHeadData = blockBox.encryptJson(headData)
        const formData = new FormData()
        formData.append("head", new Blob([b64decode(encryptedHeadData)]))
        if (encryptedBodyData) {
          formData.append("body", new Blob([encryptedBodyData]))
        }

        block = await api.base.POST<BlockInfoDTO>("/block/", formData)
        break
      } catch (e) {
        console.error(
          `Error while uploading block, ${repeatCount} retries left: ${e}`,
        )
        if (!repeatCount) {
          alert("Upload-Error")
          throw e
        }
      }
    }
    if (!block || !key || !encryptedHeadData) {
      throw new Error("Have no block")
    }

    console.log("Have new block", JSON.stringify(block))
    truthStore.update([block])

    return {
      block,
      manifestEntry: {
        id: block.id,
        key: b64encode(key),
        headHash: hash_blake2b(b64decode(encryptedHeadData)),
      },
    }
  }

  async function createBlockForSection(
    section: Section<ChapterType>,
  ): Promise<{ manifestEntry: ManifestBlockDTOv1; block: BlockInfoDTO }> {
    let body: Uint8Array | undefined = undefined

    let mappedBody: undefined | any = section.body
    if (LABELED_FILE_SECTION_TYPES.includes(section.type)) {
      mappedBody = (section as Section<LabeledFileSectionTypes>).body!.map(
        (line) => [line.block, line.label, line.source || "", line.date || ""],
      )
    } else if (WITH_FILES_SECTION_TYPES.includes(section.type)) {
      const data = (section as Section<WithFilesSectionTypes>).body!
      mappedBody = {
        ...omit(data, ["entries"]),
        entries: data.entries.map((entry) => ({
          ...omit(entry, ["files"]),
          files: entry.files.map((file) => file.sourceBlock!.id),
        })),
      }
    }

    if (mappedBody !== null && mappedBody !== undefined) {
      if (typeof mappedBody === "string") {
        // Encode raw
        body = new TextEncoder().encode(mappedBody)
      } else {
        body = new TextEncoder().encode(JSON.stringify(mappedBody))
      }
    }
    const head = {
      meta: section.meta,
      type: section.type,
    }

    return createBlockForHeadAndBody(head, body)
  }

  async function createBlockForFile(
    file: File,
    meta: Record<string, any> = {},
  ): Promise<{ manifestEntry: ManifestBlockDTOv1; block: BlockInfoDTO }> {
    return await createBlockForHeadAndBody(
      { meta: { ...meta, name: file.name, mime: file.type }, type: "File" },
      new Uint8Array(await file.arrayBuffer()),
    )
  }

  function* relatedBlockIds(
    blocks: Record<string, ManifestBlockDTOv1>,
    section: DossierContentSection,
  ) {
    if (LABELED_FILE_SECTION_TYPES.includes(section.content.type)) {
      for (const line of section.content.body as LabeledFile[]) {
        if (!line.block || !blocks[line.block]) {
          throw new Error(
            "Consistency problem: old file not in old block index",
          )
        }
        yield line.block
      }
    } else if (WITH_FILES_SECTION_TYPES.includes(section.content.type)) {
      const body = section.content
        .body as Section<WithFilesSectionTypes>["body"]
      for (const line of body!.entries) {
        for (const file of line.files) {
          if (!file.sourceBlock || !blocks[file.sourceBlock.id]) {
            throw new Error(
              "Consistency problem: old file not in old block index",
            )
          }
          yield file.sourceBlock.id
        }
      }
    }
  }

  async function saveSection(
    oldManifestBlockIndex: Record<string, ManifestBlockDTOv1>,
    section: DossierContentSection,
  ): Promise<{ manifestEntry: ManifestBlockDTOv1; block: BlockInfoDTO }[]> {
    const retval = [] as {
      manifestEntry: ManifestBlockDTOv1
      block: BlockInfoDTO
    }[]

    // Special pass: When migrating manifest versions, we'll have to re-upload files
    // To do this, we fake all file blocks as not uploaded (set block=undefined)
    if (LABELED_FILE_SECTION_TYPES.includes(section.content.type)) {
      for (const line of section.content.body as LabeledFile[]) {
        if (line.block && !oldManifestBlockIndex[line.block]) {
          line.block = undefined
        }
      }
      // As a consequence we need to force mark dirty any section including a
      // not-uploaded file
      section.dirty =
        section.dirty ||
        (section.content.body as LabeledFile[]).some((item) => !item.block)
    }

    if (
      !section.dirty &&
      section.sourceBlockId &&
      oldManifestBlockIndex[section.sourceBlockId]
    ) {
      // Already exists, return old entry, including files
      retval.push({
        manifestEntry: oldManifestBlockIndex[section.sourceBlockId],
        block: (
          await truthStore.fetch<BlockInfoDTO>(
            `/block/${section.sourceBlockId}/`,
          )
        ).value,
      })

      for (const blockId of relatedBlockIds(oldManifestBlockIndex, section)) {
        retval.push({
          manifestEntry: oldManifestBlockIndex[blockId],
          block: (await truthStore.fetch<BlockInfoDTO>(`/block/${blockId}/`))
            .value,
        })
      }

      return retval
    }

    // Create new block, with new key

    if (LABELED_FILE_SECTION_TYPES.includes(section.content.type)) {
      for (const line of (section.content as Section<LabeledFileSectionTypes>)
        .body!) {
        // Don't need to re-upload dependent block if it already exists, unless there's a format conversion

        if (!line.block) {
          const file = await loadRemoteFile(line.content)
          // Upload file
          const uploadedFile = await createBlockForFile(file, line.content.meta)
          line.block = uploadedFile.block.id
          retval.push(uploadedFile)
        } else {
          if (!line.block || !oldManifestBlockIndex[line.block]) {
            throw new Error(
              "Consistency problem: old file not in old block index",
            )
          }

          // Use old file
          retval.push({
            manifestEntry: oldManifestBlockIndex[line.block],
            block: (
              await truthStore.fetch<BlockInfoDTO>(`/block/${line.block}/`)
            ).value,
          })
        }
      }
    } else if (WITH_FILES_SECTION_TYPES.includes(section.content.type)) {
      const body = section.content
        .body as Section<WithFilesSectionTypes>["body"]
      for (const entry of body!.entries) {
        for (const file of entry.files) {
          // Don't need to re-upload dependent block if it already exists
          if (!file.sourceBlock) {
            // Upload file
            const uploadedFile = await createBlockForFile(file.body!, file.meta)
            // FIXME createBlock should return DecryptedBlock too
            const decryptedBlock: DecryptedBlock = {
              body: "DUMMY",
              bodyLoader: async () => {
                return decryptedBlock.body
              },
              head: uploadedFile.block.head as any,
              id: uploadedFile.block.id,
              url: uploadedFile.block.url,
            }
            file.sourceBlock = decryptedBlock
            retval.push(uploadedFile)
          } else {
            if (!oldManifestBlockIndex[file.sourceBlock.id]) {
              throw new Error(
                "Consistency problem: old file not in old block index",
              )
            }

            // Use old file
            retval.push({
              manifestEntry: oldManifestBlockIndex[file.sourceBlock.id],
              block: (
                await truthStore.fetch<BlockInfoDTO>(
                  `/block/${file.sourceBlock.id}/`,
                )
              ).value,
            })
          }
        }
      }
    }

    const uploadedSection = await createBlockForSection(section.content)
    section.sourceBlockId = uploadedSection.block.id
    section.dirty = false
    retval.push(uploadedSection)

    return retval
  }

  async function saveDossierContents(dossierContents: DossierContents) {
    const dossierInfoDto = await until(
      _requestDossier(dossierContents.url),
    ).toBeTruthy()
    const keyBox = await until(
      crypto.getSecretBox(dossierInfoDto.encryption_kid),
    ).toBeTruthy()
    const oldManifest = keyBox.decryptJson<DossierManifestDTO>(
      dossierInfoDto.manifest,
    )

    const oldManifestBlockIndex: Record<string, ManifestBlockDTOv1> = {}
    // For manifests version < 1, pretend the block index is empty to enforce a full save
    if (oldManifest.version !== undefined || oldManifest.version === 1) {
      for (const block of oldManifest.blocks) {
        oldManifestBlockIndex[block.id] = block
      }
    }

    const sectionList = dossierContents.rows.reduce(
      (acc, cur) => [...acc, ...cur],
      [] as DossierContentSection[],
    )

    // Run save in parallel
    const savedItems: {
      manifestEntry: ManifestBlockDTOv1
      block: BlockInfoDTO
    }[][] = await Promise.all(
      sectionList.map((section) => saveSection(oldManifestBlockIndex, section)),
    )

    // Save meta block if changed
    let metaBlock: (typeof savedItems)[0][0] | null = null
    let metaBlockId = isDossierManifestV1(oldManifest)
      ? oldManifest?.metaBlockId
      : null
    if (!metaBlockId || dossierContents.meta.dirty) {
      metaBlock = await createBlockForHeadAndBody(
        { type: "DossierMeta" },
        new TextEncoder().encode(
          JSON.stringify(
            omit(dossierContents.meta, ["dirty", "sourceBlockId"]),
          ),
        ),
      )
      dossierContents.meta.dirty = false
    } else {
      metaBlock = {
        manifestEntry: oldManifestBlockIndex[metaBlockId],
        block: (await truthStore.fetch<BlockInfoDTO>(`/block/${metaBlockId}/`))
          .value,
      }
    }

    savedItems.push([metaBlock])
    metaBlockId = metaBlock.block.id

    const newManifestBlocks: ManifestBlockDTOv1[] = []
    const newBlocks: Record<ApiUrl, BlockInfoDTO> = {}
    for (const saveResult of savedItems) {
      newManifestBlocks.push(...saveResult.map((item) => item.manifestEntry))
      for (const entry of saveResult) {
        newBlocks[entry.block.url] = entry.block
      }
    }

    const newManifestBlockIds = newManifestBlocks.map(
      (manifestBlock) => manifestBlock.id,
    )
    // Important: oldManifestBlockIndex may be faked, need to calculate this fresh
    const oldManifestBlockIds = oldManifest.blocks.map((block) => block.id)

    const newManifest: DossierManifestDTOv1 = {
      version: 1,
      level: oldManifest.level,
      lastChange: new Date().toISOString(),
      blocks: newManifestBlocks,
      metaBlockId,
      display: {
        layout: LayoutDesign.Layout1,
      },
    }

    const addBlocks = difference(newManifestBlockIds, oldManifestBlockIds)
    const delBlocks = difference(oldManifestBlockIds, newManifestBlockIds)
    const ops = new AtomicOperator(api.base as ApiBase)

    ops.extend(
      _mutateDossierOps(dossierInfoDto, {
        manifest: keyBox.encryptJson(newManifest),
      }),
    )

    for (const deleteBlockId of delBlocks) {
      ops.remove(
        await until(
          await truthStore.fetch<BlockInfoDTO>(`/block/${deleteBlockId}/`),
        ).toBeTruthy(),
      )
    }
    for (const newBlock of Object.values(newBlocks)) {
      if (addBlocks.includes(newBlock.id)) {
        ops.replace(newBlock, "dossier", dossierInfoDto)
        ops.output(newBlock)
      }
    }

    ops.output(dossierInfoDto)

    truthStore.update(await ops.do())

    return dossierContents
  }

  function urlForId(id: string): ApiUrl {
    return api.base.normalizeUrl(`dossier/${id}/`)
  }

  async function createDossierLink(
    dossier: Partial<DossierInfoDTO> & APIReferenceable & EncryptedObj,
    slug?: string,
    password?: Uint8Array,
  ): Promise<CreatedLink> {
    // Creation order: a) Create Link with empty key data and empty endorsement
    //  just to register the object. It is in an invalid state that cannot be read
    //  b) complete the handshake, receive export_key
    //  c) Update Link object for final state
    if (!userStore.currentUser) {
      throw new Error("Not logged in ")
    }
    const me = userStore.currentUser
    const dossierLinks = linksByDossierUrl.get(dossier.url) || []

    if (slug === undefined) slug = generateRandomString(12)
    if (password === undefined) password = nacl.random_bytes(16)

    let link = (
      await new AtomicOperator(api.base as ApiBase)
        .add<LinkDTO>("/link/", {
          slug,
          dossier,
          keybox: "",
          private_data: "",
          endorsed_by: me,
        })
        .do()
    )[0]

    const { op, export_key } = await crypto.register(link, password)

    const exportBox = new SecretBoxJson(export_key.slice(0, 32))
    const apKey = nacl.random_bytes(32)
    const apBox = new SecretBoxJson(apKey)
    const entityBox = EntityPrivateBox.generate(link.id)

    op.extend(
      _mutateLinkOps(link, {
        private_data: exportBox.encryptJson({ box_key: b64encode(apKey) }),
        keybox: entityBox.wrapFor(apBox),
        ...entityBox.dumpPublic(),
      }),
    )

    op.add(
      `/associatedkey/${dossier.encryption_kid}/${link.id}`,
      crypto.manager!.endorse(
        ENDORSEMENT.AssociatedKey,
        {
          wrapped_key: crypto
            .getSecretBox(dossier.encryption_kid)
            .value!.wrapFor(entityBox), // FIXME value is not guaranteed
        },
        {
          kid: dossier.encryption_kid,
          entity: link,
        },
      ),
    )

    op.output(link)
    link = truthStore.update((await op.do()) as any).pop() as LinkDTO

    const returnedLink = {
      ...link,
      password: b64encodeUrlsafe(password),
    } as CreatedLink
    dossierLinks.push(returnedLink)
    linksByDossierUrl.set(dossier.url, dossierLinks)

    return linksByDossierUrl.get(dossier.url)!.at(-1) as CreatedLink
  }

  async function disableDossierLink(url: ApiUrl) {
    const linkObj = await truthStore.fetch<LinkDTO>(url)
    if (!linkObj || !linkObj.value) {
      throw new Error("Link not loaded")
    }

    const dossier = await until(
      truthStore.data.get<DossierInfoDTO>(linkObj.value.dossier),
    ).toBeTruthy()

    const dossierLinks = linksByDossierUrl.get(linkObj.value.dossier)
    if (!dossierLinks) {
      throw new Error("Link dossier not in dossier link list")
    }

    const dossierLinkIndex = dossierLinks.findIndex(
      (item) => item.url === linkObj.value.url,
    )
    if (dossierLinkIndex === undefined) {
      throw new Error("Link not in dossier link list")
    }

    // Backend currently requires CAS test on AssociatedKey. Not sure if this is generally a good idea though
    const ak = await until(
      truthStore.data.get<AssociatedKey>(
        `associatedkey/${dossier.encryption_kid}/`,
      ),
    ).toBeTruthy()

    const ops = new AtomicOperator(api.base as ApiBase)
      .rawOperation({
        op: "test",
        path: `/associatedkey/${dossier.encryption_kid}/${
          userStore.currentUser!.id
        }/cas_hash`,
        value: ak.cas_hash,
      })
      .replace(linkObj.value, "is_active", false)
      .rawOperation({
        op: "remove",
        path: `/associatedkey/${dossier.encryption_kid}/${linkObj.value.id}`,
      })
      .output(linkObj.value)

    const newLink = (await ops.do()).pop() as LinkDTO

    truthStore.update([newLink])
    dossierLinks[dossierLinkIndex] = newLink
    linksByDossierUrl.set(dossier.url, dossierLinks)
    return dossierLinks[dossierLinkIndex]
  }

  async function setDossierLinkPinState(
    linkObj: Ref<LinkDTO>,
    newState: PinState,
  ) {
    const dossier = await until(
      truthStore.data.get<DossierInfoDTO>(linkObj.value.dossier),
    ).toBeTruthy()

    const dossierLinks = linksByDossierUrl.get(linkObj.value.dossier)
    if (!dossierLinks) {
      throw new Error("Link dossier not in dossier link list")
    }

    const dossierLinkIndex = dossierLinks.findIndex(
      (item) => item.url === linkObj.value.url,
    )
    if (dossierLinkIndex === undefined) {
      throw new Error("Link not in dossier link list")
    }
    const ops = new AtomicOperator(api.base as ApiBase)
      .replace(linkObj.value, "pin_state", newState)
      .output(linkObj.value)

    const newLink = (await ops.do()).pop() as LinkDTO

    truthStore.update([newLink])
    dossierLinks[dossierLinkIndex] = newLink
    linksByDossierUrl.set(dossier.url, dossierLinks)
    return dossierLinks[dossierLinkIndex]
  }

  async function setUserRole(
    dossier: TestableObject & { users: UserMapping[]; encryption_kid: string },
    role: UserDossierRole,
    user: User | null,
  ) {
    const index = dossier.users.findIndex((du) => du.role === role)
    if (index === -1 && !user) return

    const ops = new AtomicOperator(api.base as ApiBase)

    // FIXME Might not have been loaded
    if (index !== -1)
      ops.remove(dossier, [
        "users",
        userStore.requestUser(dossier.users[index].user).value!,
      ])
    if (user) ops.add(dossier, "users", { role: role, user: user })

    // FIXME Correctly figure out if the user already has the key.
    // For now we assume that all superusers got the key at initial creation
    if (user && user.type !== "superuser") {
      const akInfo = crypto.getEntityBoxInfo(user)
      ops.test(akInfo.obj).add(
        `/associatedkey/${dossier.encryption_kid}/${akInfo.entity_id}`,
        crypto.manager!.endorse(
          ENDORSEMENT.AssociatedKey,
          {
            wrapped_key: crypto
              .getSecretBox(dossier.encryption_kid)
              .value!.wrapFor(akInfo.box), // FIXME value is not guaranteed
          },
          {
            kid: dossier.encryption_kid,
            entity: akInfo.obj as User,
          },
        ),
      )
    }
    ops.output(dossier)

    truthStore.update(await ops.do())
  }

  async function createEmptyDefaultDossier({
    code_name,
    level,
    customerName,
    customerNumber,
    articleNumber,
  }: {
    code_name: string
    level: DossierLevel
    customerName?: string
    customerNumber?: string
    articleNumber?: string
  }): Promise<string> {
    const dossierKey = nacl.random_bytes(32)
    const dossierBox = new SecretBoxJson(dossierKey)
    const encryption_kid = window.crypto.randomUUID()
    const me = userStore.currentUser!

    const meta: DossierMetaDTO = {
      title: "",
      customerName,
      customerNumber,
      articleNumber,
      company: [],
      person: [],
    }
    const sections: Section<ChapterType>[] = DEFAULT_SECTIONS

    const manifest: DossierManifestDTOv1 = {
      version: 1,
      level,
      lastChange: new Date().toISOString(),
      blocks: [],
      display: { layout: LayoutDesign.Layout1 },
      metaBlockId: undefined,
    }

    // Workaround: Make sure user store is loaded
    await userStore.fetchUsers()

    // Note: When creating a Dossier the creating user (us) is automatically
    // set as Profiler. To mirror that, we add a default AssociatedKey for us here
    // Also we need to set AKs for all users that should get access
    const dossierCreateOps = new AtomicOperator(api.base as ApiBase)
    dossierCreateOps.extend(
      _createAssociatedKeyOps(encryption_kid, dossierKey, me),
    )

    dossierCreateOps.add(
      "/dossier/",
      crypto.manager!.endorse(ENDORSEMENT.Dossier, {
        id: window.crypto.randomUUID(),
        code_name,
        encryption_kid,
        manifest: dossierBox.encryptJson(manifest),
      }),
    )
    const dossier: DossierInfoDTO = truthStore
      .update(await dossierCreateOps.do())
      .pop() as any as DossierInfoDTO

    // This takes care of the initial loadout of an empty dossier, we'll add content now

    // bulk save all blocks
    const savedBlocks = await Promise.all(
      sections.map((c) => createBlockForSection(c)),
    )
    const metaBlock = await createBlockForHeadAndBody(
      { type: "DossierMeta" },
      new TextEncoder().encode(JSON.stringify(meta)),
    )
    savedBlocks.push(metaBlock)
    manifest.metaBlockId = metaBlock.block.id

    const ops = new AtomicOperator(api.base as ApiBase)
    // todo: handle referenced blocks

    for (const { block, manifestEntry } of savedBlocks) {
      manifest.blocks.push(manifestEntry)
      ops.replace(block, "dossier", dossier)
      ops.output(block)
    }

    ops.extend(
      _mutateDossierOps(dossier, {
        manifest: dossierBox.encryptJson(manifest),
      }),
    )
    ops.output(dossier)

    truthStore.update(await ops.do())
    return dossier.id
  }

  async function updateDossierDisplayLayout(
    dossier: any,
    newLayout: LayoutType,
  ) {
    const dossierInfoDto = _requestDossier(dossier.url)

    if (!dossierInfoDto.value) {
      return null
    }
    const keyBox = crypto.getSecretBox(dossierInfoDto.value.encryption_kid)
    await until(computed(() => keyBox.value)).toBeTruthy()
    if (!keyBox.value) {
      return null
    }

    loading.progress("setup", 1)

    let manifest = keyBox.value.decryptJson<DossierManifestDTO>(
      dossierInfoDto.value.manifest,
    )

    if (isDossierManifestV1(manifest)) {
      manifest = {
        ...manifest,
        display: { layout: newLayout },
      }
    } else {
      // do nothing
    }
    const ops = new AtomicOperator(api.base as ApiBase)
    ops.extend(
      _mutateDossierOps(dossier, {
        manifest: keyBox.value.encryptJson(manifest),
      }),
    )
    ops.output(dossier)
    truthStore.update(await ops.do())
  }

  async function updateDossierLevel(dossier: any, level: DossierLevel) {
    const dossierInfoDto = _requestDossier(dossier.url)

    if (!dossierInfoDto.value) {
      return null
    }
    const keyBox = crypto.getSecretBox(dossierInfoDto.value.encryption_kid)
    await until(computed(() => keyBox.value)).toBeTruthy()
    if (!keyBox.value) {
      return null
    }

    loading.progress("setup", 1)

    let manifest = keyBox.value.decryptJson<DossierManifestDTO>(
      dossierInfoDto.value.manifest,
    )

    if (isDossierManifestV1(manifest)) {
      manifest = {
        ...manifest,
        level,
      }
    }

    const ops = new AtomicOperator(api.base as ApiBase)
    ops.extend(
      _mutateDossierOps(dossier, {
        manifest: keyBox.value.encryptJson(manifest),
      }),
    )
    ops.output(dossier)
    truthStore.update(await ops.do())
  }

  async function deleteDossier(dossierUrl: string) {
    const dossier = await until(
      truthStore.data.get<DossierInfoDTO>(dossierUrl),
    ).toBeTruthy()
    const ops = new AtomicOperator(api.base as ApiBase)
    ops.remove(dossier)
    truthStore.update(await ops.do())
  }

  return {
    updateDossierDisplayLayout,
    updateDossierLevel,
    byUrl,
    dossiers,
    dossiersSorted,
    linksByDossierUrl,
    fetchDossiers,
    getDossierOverview,
    getDossierContents,
    loadDossierPreview,
    loadRemoteFile,
    urlForId,
    loading,
    currentLayout,
    setUserRole,
    createDossierLink,
    disableDossierLink,
    setDossierLinkPinState,
    createEmptyDefaultDossier,
    saveDossierContents,
    deleteDossier,
    printMode,
  }
})
