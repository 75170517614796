<template>
  <div style="height: 100%; width: 100%">
    <v-network-graph
      ref="graph"
      :nodes="nodesX"
      :edges="edges"
      :zoom-level="preLayouts?.zoomLevel"
      :layouts="preLayouts"
      :configs="configs"
      :event-handlers="isSocial ? {} : eventHandlers"
      @update:layouts="updateLayouts"
    >
      <defs>
        <clipPath id="nodeClipPath" clipPathUnits="objectBoundingBox">
          <circle cx="0.5" cy="0.5" r="0.5" />
        </clipPath>
      </defs>
      <template #edge-label="{ edge, ...slotProps }">
        <v-edge-label
          :text="edge.relationship"
          align="center"
          vertical-align="above"
          v-bind="slotProps"
        />
      </template>
      <template #override-node="{ nodeId, scale, config, ...slotProps }">
        <circle
          v-if="!isSocial || (isSocial && nodes[nodeId].isParent)"
          class="graph-circle"
          :r="
            config.radius * (nodes[nodeId].isParent ? scale + 0.2 : scale - 0.1)
          "
          :fill="isSocial ? nodes[nodeId].color : '#fff'"
          v-bind="slotProps"
          :stroke="
            isSocial
              ? nodes[nodeId].color
              : !nodes[nodeId].imageUrl && colors.primary
          "
          stroke-width="2"
        />
        <image
          v-if="nodes[nodeId].imageUrl"
          :x="
            -config.radius *
            scale *
            (nodes[nodeId].isParent ? scale + 0.2 : scale - 0.1)
          "
          :y="
            -config.radius *
            scale *
            (nodes[nodeId].isParent ? scale + 0.2 : scale - 0.1)
          "
          :width="
            config.radius *
            scale *
            2 *
            (nodes[nodeId].isParent ? scale + 0.2 : scale - 0.1)
          "
          :height="
            config.radius *
            scale *
            2 *
            (nodes[nodeId].isParent ? scale + 0.2 : scale - 0.1)
          "
          :xlink:href="nodes[nodeId].imageUrl"
          clip-path="url(#nodeClipPath)"
        />
        <template v-else-if="!(isSocial && !nodes[nodeId].isParent)">
          <image
            v-if="nodes[nodeId].icon"
            :x="
              -config.radius *
              scale *
              (nodes[nodeId].isParent ? scale + 0.2 : scale - 0.1) *
              0.8
            "
            :y="
              -config.radius *
              scale *
              (nodes[nodeId].isParent ? scale + 0 : scale - 0.1) *
              0.8 *
              (nodes[nodeId].initial ? 1.33 : 1)
            "
            :width="
              config.radius *
              scale *
              2 *
              (nodes[nodeId].isParent ? scale + 0.2 : scale - 0.1) *
              0.8
            "
            :height="
              config.radius *
              scale *
              2 *
              (nodes[nodeId].isParent ? scale + 0.2 : scale - 0.1) *
              0.8 *
              (nodes[nodeId].initial ? 0.77 : 1)
            "
            :xlink:href="
              encodeIcon(nodes[nodeId].icon, isSocial ? '#fff' : colors.primary)
            "
          />
          <text
            v-if="nodes[nodeId].initial"
            :stroke="colors.primary"
            text-anchor="middle"
            :transform="`scale(${scale * 0.5}) translate(0 ${
              config.radius * scale * 1.5
            })`"
            >{{ nodes[nodeId].initial }}</text
          >
        </template>

        <template v-if="isSocial && !nodes[nodeId].isParent">
          <g
            :transform="`translate(-${config.width / 2}, -${
              config.height / 8
            })`"
          >
            <rect
              x="0"
              y="-15"
              :width="config.width"
              :height="config.height"
              :fill="nodes[nodeId].color"
              rx="12"
              ry="12"
            />

            <circle
              cx="185"
              class="graph-circle"
              :r="13"
              :fill="'#fff'"
              :stroke="isSocial ? nodes[nodeId].color : colors.primary"
              stroke-width="1"
            />
            <text y="8" x="195" text-anchor="end" font-size="130%"
              ><a
                :xlink:href="nodes[nodeId].url"
                target="__blank"
                :fill="isSocial ? nodes[nodeId].color : colors.primary"
                >&#x2B08;
              </a></text
            >
            <text fill="#fff" y="5" x="15" text-anchor="start" font-size="90%">
              {{
                nodes[nodeId].name.length > 20
                  ? nodes[nodeId].name.slice(0, 20) + "..."
                  : nodes[nodeId].name
              }}</text
            >
          </g>
        </template>
      </template>
    </v-network-graph>
    <div style="position: absolute; right: 0; top: 0; z-index: 2">
      <v-btn-group
        color="primary"
        class="ma-2"
        variant="outlined"
        density="comfortable"
        rounded
      >
        <v-btn :icon="mdiMagnifyMinusOutline" @click="graph.zoomOut()" />
        <v-btn :icon="mdiMagnifyPlusOutline" @click="graph.zoomIn()" />
      </v-btn-group>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { VEdgeLabel, VNetworkGraph } from "v-network-graph"
import * as vNG from "v-network-graph"
import { ForceLayout } from "v-network-graph/lib/force-layout"
import { useHighlightStore } from "@/common/store/view/highlight"
import { mdiMagnifyMinusOutline, mdiMagnifyPlusOutline } from "@mdi/js"
import { computed, ref, watch } from "vue"
import { IconType } from "@/common/store/dossier"
import { refDebounced } from "@vueuse/core"
import { useTheme } from "vuetify/lib/framework.mjs"
import { ExtendedLayouts } from "@/common/lib/types"
const theme = useTheme()
const colors = theme.current.value.colors

const { hOn, hOff } = useHighlightStore()

const graph = ref()
const props = defineProps<{
  nodes: any
  edges: any
  isSocial?: boolean
  preLayouts?: ExtendedLayouts
}>()
const emit = defineEmits(["showEntity", "update:networkPositionsOut"])

const nodeOver = ref<null | string>(null)
const nodeHighlighted = refDebounced(nodeOver, 100)
const nodesX = computed(() =>
  Object.fromEntries(
    Object.entries(props.nodes).map(([key, value]: [any, any]) => [
      key,
      {
        ...value,
        name: key === nodeHighlighted.value ? value.name : undefined,
      },
    ]),
  ),
)

function encodeIcon(icon: string | IconType, fill: string = "#fff") {
  let iconPath
  let width = 24
  let height = 24
  let scale = 0.9

  if (typeof icon !== "string" && icon?.icon) {
    width = icon.icon[0]
    height = icon.icon[1]
    iconPath = `${icon.icon[4]}`
  } else {
    iconPath = icon
  }

  const tx = ((1 - scale) / 2) * width
  const ty = ((1 - scale) / 2) * height

  const innerSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}" role="img" aria-hidden="true"><path d="${iconPath}" fill="${fill}" transform="scale(${scale}) translate(${tx}, ${ty})"></path></svg>`
  return "data:image/svg+xml;base64," + btoa(innerSvg)
}
function updateUniqueSourceX(edges: any) {
  const seenSources: any = {}
  let startX: number = 200
  let currentX: number = 200
  let currentY: number = 200
  let rowWidth: number = 550
  let rowHeight: number = 450
  return edges.map((item: any) => {
    const sourceId = item.source.id
    if (!seenSources[sourceId]) {
      seenSources[sourceId] = true
      item.source.x = currentX
      item.source.y = currentY
      currentX += rowWidth
      if (currentX > startX + rowWidth) {
        currentX = startX
        currentY += rowHeight
      }
    }
    return item
  })
}

const configs = vNG.defineConfigs({
  width: 200,
  view: {
    autoPanAndZoomOnLoad: props.preLayouts?.zoomLevel
      ? undefined
      : "fit-content",
    scalingObjects: true,
    mouseWheelZoomEnabled: false,
    fitContentMargin: props.isSocial ? 14 : 50,
    layoutHandler:
      !props.preLayouts || props.isSocial
        ? new ForceLayout({
            positionFixedByDrag: false,
            positionFixedByClickWithAltKey: false,
            noAutoRestartSimulation: true,
            createSimulation: (d3, nodes, edges: any) => {
              const forceLink = d3.forceLink(edges).id((d: any) => d.id)
              const simulation = d3.forceSimulation(nodes).tick(200)

              if (!props.isSocial) {
                simulation
                  .force("edge", forceLink.distance(100).strength(3))
                  .force("charge", d3.forceManyBody().strength(-2000))
                  .force("x", d3.forceX().strength(0.5))
                  .force("y", d3.forceY().strength(0.5))
                  .stop()
                  .tick(200)
              }
              if (props.isSocial) {
                simulation
                  .force("edge", forceLink.distance(0).strength(0))
                  .force("charge", d3.forceManyBody().strength())
                  .force("x", d3.forceX().strength(0.2))
                  .force("y", d3.forceY().strength(0.2))
                  .force("straightLine", () => {
                    if (props.isSocial) {
                      updateUniqueSourceX(edges)
                      edges.forEach((edge: any) => {
                        const sourceEdges = edges.filter(
                          (e: any) => e.source === edge.source,
                        )
                        const currentSourceEdgesIndex = sourceEdges.findIndex(
                          (e: any) => e === edge,
                        )
                        sourceEdges.forEach(() => {
                          const baseDistance = 140
                          const distanceIncrement = 50
                          const elementsPerIncrement = 4
                          let distance =
                            baseDistance +
                            Math.floor(
                              currentSourceEdgesIndex / elementsPerIncrement,
                            ) *
                              distanceIncrement
                          if (currentSourceEdgesIndex % 2 !== 0) {
                            const thresholdIndex = 4
                            const indexDifference =
                              currentSourceEdgesIndex - thresholdIndex
                            const incrementCount = Math.floor(
                              indexDifference / 4,
                            )
                            distance += 200 * (incrementCount + 1)
                          }
                          const quarterAngle = Math.PI / 2
                          const targetAngle =
                            quarterAngle * currentSourceEdgesIndex +
                            Math.floor(currentSourceEdgesIndex / 4) * Math.PI
                          edge.target.x =
                            edge.source.x + Math.sin(targetAngle) * distance
                          edge.target.y =
                            edge.source.y - Math.cos(targetAngle) * distance
                        })
                      })
                    }
                  })
                  .stop()
                  .tick(200)
              }
              return simulation
            },
          })
        : undefined,
  },
  node: {
    normal: {
      width: 200,
      height: 30,
    },
    label: {
      visible: !props.isSocial,
      fontSize: 9,
      color: "#ffffff",
      background: {
        visible: true,
        color: "#000000aa",
        padding: { horizontal: 3, vertical: 0 },
      },
    },
  },
  edge: {
    normal: {
      color: "#3355bb",
    },
    label: { color: "#3355bb", fontSize: 8 },
  },
})

const eventHandlers: vNG.EventHandlers = {
  "node:click": ({ node }) => {
    if (["person", "company"].includes(node.split("/", 1)[0])) {
      emit("showEntity", node)
    }
  },
  "node:pointerover": ({ node }) => {
    nodeOver.value = node
    hOn(node, "mgd")
  },
  "node:pointerout": ({ node }) => {
    hOff(node, "mgd")
    nodeOver.value = null
  },
  "view:zoom": (zoom) => {
    updateLayouts(undefined, zoom)
  },
}

const payload = ref<ExtendedLayouts>({
  zoomLevel: props.preLayouts?.zoomLevel,
  nodes: props.preLayouts?.nodes || {},
})
const updateLayouts = (event?: ExtendedLayouts, zoom?: number) => {
  if (zoom) {
    payload.value.zoomLevel = zoom
  }
  if (event) {
    payload.value.nodes = event.nodes
  }

  emit("update:networkPositionsOut", payload.value)
}
</script>

<style lang="scss">
.with-content {
  width: fit-content;
  padding: 2px 8px;
  border-radius: 8px;
  text-align: center;
  background-color: rgb(var(--v-theme-primary));
  a {
    color: #fff;
    text-decoration: none;
  }
}
</style>
