<script lang="ts" setup>
import { DossierView, ReportInfo } from "@/common/store/dossier/types/view"
import CardBlock from "@/common/components/view/CardBlock.vue"
import WordCloud from "@/common/components/view/WordCloud.vue"
import GraphCategoryYear from "@/common/components/view/GraphCategoryYear.vue"
import GraphKpi from "@/common/components/view/GraphKpi.vue"
import { mdiClose } from "@mdi/js"
import { useDossierStore } from "@/common/store/dossier"
import { storeToRefs } from "pinia"
import { vOnClickOutside } from "@vueuse/components"
import type { OnClickOutsideHandler } from "@vueuse/core"

const props = defineProps<{
  paragraph?: string | null
  otherReports?: ReportInfo[]
  dossierPreview?: DossierView | null
  yearEvents?: Record<number, Record<string, number>>
}>()

const modelValue = defineModel<boolean>()

const { currentLayout } = storeToRefs(useDossierStore())
const animationOptions = {
  animations: {
    enabled: true,
    easing: "easeinout",
    speed: 800,
    animateGradually: {
      enabled: true,
      delay: 1500,
    },
    dynamicAnimation: {
      enabled: true,
      speed: 350,
    },
  },
}

const drawerHandler: OnClickOutsideHandler = () => {
  modelValue.value = false
}
</script>

<template>
  <v-navigation-drawer
    temporary
    width="650"
    location="right"
    :scrim="false"
    v-model="modelValue"
  >
    <a id="reports" />
    <v-btn
      variant="plain"
      :icon="mdiClose"
      color="primary"
      @click="modelValue = false"
      style="position: absolute; top: 0; right: 0; z-index: 2"
      class="mr-2 pa-0"
    />
    <v-sheet
      class="pa-2 d-flex flex-column"
      color="transparent"
      v-on-click-outside.bubble="drawerHandler"
    >
      <template v-for="(report, i) of otherReports" :key="i">
        <CardBlock
          v-if="report.richContent"
          col-span="2"
          :title="report.title"
          :style="`order:${report.drawerOrder}`"
          class="mb-4"
        >
          <v-card-text
            ><div class="rich-content" v-html="report.richContent"></div
          ></v-card-text>
        </CardBlock>
      </template>

      <CardBlock
        class="mb-4"
        title="Alle Suchergebnisse je Jahr"
        tooltip="Diagramm Suchergebnisse"
        v-if="yearEvents && currentLayout == 'knapp'"
      >
        <GraphCategoryYear
          :model-value="yearEvents"
          :options="{ ...animationOptions }"
        />
      </CardBlock>
      <v-row>
        <v-col cols="6">
          <!-- 1C Radar Chart Of Domains -->
          <template
            v-if="
              dossierPreview!.metrics.some((item) =>
                item.data.some((data) => data.value),
              )
            "
          >
            <CardBlock
              class="mb-4"
              v-for="(metric, i) of dossierPreview!.metrics"
              :key="i"
              :title="metric.title"
              center
            >
              <GraphKpi
                v-if="metric.type == 'kpi'"
                :model-value="metric.data"
                :options="{ ...animationOptions }"
              />
            </CardBlock>
          </template>
        </v-col>
        <v-col cols="6" v-if="currentLayout == 'knapp'">
          <CardBlock
            class="mb-4"
            v-if="dossierPreview"
            title="Häufigste Worte"
            tooltip="Häufung von Begriffen in Berichten und Labeln"
          >
            <WordCloud :view="dossierPreview" />
          </CardBlock>
        </v-col>
      </v-row>
    </v-sheet>
  </v-navigation-drawer>
</template>
