import { defineStore } from "pinia"
import { makeObjectStore, opsHelpers } from "@/common/store/_helper"
import { useCryptoStore } from "@/common/store/crypto"
import { useApiStore } from "@/common/store/api"
import {
  ApiUrl,
  EndorsedObj,
  EntityPublic,
  InputType,
  ObjectBase,
} from "@/common/lib/types"
import { computed, watch } from "vue"
import { useAuthStore } from "@/common/store/auth"
import {
  CreatedWorkstation,
  useWorkstationStore,
  WorkstationDTO,
} from "@/common/store/workstation"
import nacl from "@/common/lib/nacl"
import { b64encode, b64encodeUrlsafe } from "@/common/lib/encoding"
import { ENDORSEMENT } from "@/common/lib/crypto/endorsement"
import {
  EntityPrivateBox,
  EntityPublicBox,
  SecretBoxJson,
} from "@/common/lib/crypto"
import { AtomicOperator } from "@/common/lib/AtomicOperator"
import { ApiBase } from "@/common/lib/api"
import { useDossierStore } from "@/common/store/dossier"
import { useTruthStore } from "@/common/store/truth"
import { until } from "@vueuse/core"
import * as Sentry from "@sentry/browser"
import config from "@/config"
import { ROLE_PERMISSIONS } from "@/common/constants"

export type UserType = "superuser" | "profiler" | "analyst"
export type UserAction =
  | "show_dossier"
  | "create_dossier"
  | "delete_dossier"
  | "edit_dossier"
  | "update_profiler"
  | "update_analyst"
  | "update_level"
  | "create_link"

export type User = ObjectBase &
  EntityPublic &
  EndorsedObj & {
    display_name: string
    type: UserType
    is_active: boolean
  }
export type CreatedUser = User & {
  initialWorkstation: CreatedWorkstation
}

export function canPerformAction(
  role: UserType | null | undefined,
  action: UserAction,
): boolean {
  if (!role) return false
  const allowedActions = ROLE_PERMISSIONS[role] || []
  return allowedActions.includes(action)
}

export const useUserStore = defineStore("user", () => {
  const {
    objects: users,
    byUrl,
    request: requestUser,
    fetchAll: fetchUsers,
  } = makeObjectStore<User>("user/")
  const { _mutateUserOps } = opsHelpers()

  const workstationStore = useWorkstationStore()
  const dossierStore = useDossierStore()
  const truthStore = useTruthStore()
  const userStore = useUserStore()
  const crypto = useCryptoStore()
  const auth = useAuthStore()
  const api = useApiStore()

  const currentUser = computed(() => {
    if (crypto.ready && api.ready) {
      // FIXME Special case for link
      if (auth.session?.entity.id === auth.session?.accessPoint.id) {
        return null
      }
      const user = requestUser(auth.session?.entity.url!)
      return user.value || null
    }
    return null
  })

  watch(currentUser, (user) => {
    if (config.sentry.dsn) {
      if (!user) {
        Sentry.getGlobalScope().setUser(null)
      } else {
        Sentry.getGlobalScope().setUser({
          id: user.id,
          username: user.display_name,
        })
      }
    } else {
      console.log("Sentry inactive")
    }
  })

  async function _seedAssociatedKeys(
    user: User,
    type: UserType,
    entityBox: EntityPublicBox,
  ) {
    // Fetch, no cache, list of all dossiers, then fetch all necessary associated keys
    const dossiers = await dossierStore.fetchDossiers()
    const keyIds = [] as string[]

    if (type === "superuser") {
      // Needs all keys
      keyIds.push(...dossiers.value.map((dossier) => dossier.encryption_kid))
    } else {
      // Only keys of assigned dossiers
      keyIds.push(
        ...dossiers.value
          .filter((dossier) =>
            dossier.users.some((userRole) => userRole.user === user.url),
          )
          .map((dossier) => dossier.encryption_kid),
      )
    }

    const boxes = await Promise.all(
      keyIds.map(async (kid) => {
        return {
          kid,
          box: await until(crypto.getSecretBox(kid)).toBeTruthy(),
        }
      }),
    )
    const akOps = new AtomicOperator(api.base as ApiBase).test(user)
    for (const { kid, box } of boxes) {
      akOps.add(
        `/associatedkey/${kid}/${user.id}`,
        crypto.manager!.endorse(
          ENDORSEMENT.AssociatedKey,
          {
            wrapped_key: box.wrapFor(entityBox),
          },
          {
            kid,
            entity: user,
          },
        ),
      )
    }
    await akOps.do()
  }

  async function _initializeInitialWorkstationAndSeedAssociatedKeys(
    op: AtomicOperator<void[]>,
    password: Uint8Array,
    export_key: Uint8Array,
    type: UserType,
    user: User,
    workstation: WorkstationDTO,
  ) {
    const exportBox = new SecretBoxJson(export_key.slice(0, 32))
    const apKey = nacl.random_bytes(32)
    const apBox = new SecretBoxJson(apKey)
    const entityBox = EntityPrivateBox.generate(user.id)

    op.replace(
      workstation,
      "private_data",
      exportBox.encryptJson({ box_key: b64encode(apKey) }),
    )

    op.extend(
      _mutateUserOps(user, {
        keybox: entityBox.wrapFor(apBox),
        is_active: true,
        ...entityBox.dumpPublic(),
      }),
    )

    op.output(workstation)
    op.output(user)
    const results = (await op.do()) as unknown as [WorkstationDTO, User]
    truthStore.update(results.slice(-2))

    user = truthStore.data.get<User>(user.url).value!
    workstation = truthStore.data.get<WorkstationDTO>(workstation.url).value!

    await _seedAssociatedKeys(user, type, entityBox)

    return {
      ...user,
      initialWorkstation: {
        ...workstation,
        password: b64encodeUrlsafe(password),
      },
    }
  }

  async function createUser(
    type: UserType,
    display_name: string,
  ): Promise<CreatedUser> {
    // Create cryptographic keys
    // Create user (entity)
    // Create workstation (access point)
    // Delegate associated keys if necessary (superuser)

    const me = userStore.currentUser!
    const password = nacl.random_bytes(16)

    const user = (
      await new AtomicOperator(api.base as ApiBase)
        .add<User>("/user/", {
          type,
          display_name,
          endorsement: "",
          endorsed_by: me,
          endorsement_chain: "",
          signature_pubkey: "",
          sealedbox_pubkey: "",
          is_active: false,
        })
        .do()
    )[0]

    const workstation = (
      await new AtomicOperator(api.base as ApiBase)
        .add<WorkstationDTO>("/workstation/", {
          user: user,
          name: `Workstation ${display_name}`,
        })
        .do()
    )[0]

    const { op, export_key } = await crypto.register(workstation, password)

    return await _initializeInitialWorkstationAndSeedAssociatedKeys(
      op,
      password,
      export_key,
      type,
      user,
      workstation,
    )
  }

  async function resetUser(url: ApiUrl): Promise<CreatedUser> {
    const user = await until(requestUser(url)).toBeTruthy()
    const password = nacl.random_bytes(16)

    const workstation = (
      await new AtomicOperator(api.base as ApiBase)
        .add<WorkstationDTO>("/workstation/", {
          user: user,
          name: `Workstation ${user.display_name}`,
        })
        .do()
    )[0]

    const { op, export_key } = await crypto.register(workstation, password)

    const allWorkstations = await workstationStore.fetchWorkstations()
    const userWorkstations = allWorkstations.value.filter(
      (ws) => ws.user === user.url,
    )

    for (const otherWs of userWorkstations) {
      if (otherWs.id !== workstation.id) {
        op.remove(otherWs)
      }
    }

    const retval = await _initializeInitialWorkstationAndSeedAssociatedKeys(
      op,
      password,
      export_key,
      user.type,
      user,
      workstation,
    )

    for (const otherWs of userWorkstations) {
      if (otherWs.id !== workstation.id) {
        truthStore.data.set(otherWs.url, undefined)
      }
    }

    return retval
  }

  async function changeUser(url: ApiUrl, changes: Partial<InputType<User>>) {
    const user = await until(requestUser(url)).toBeTruthy()
    const ops = new AtomicOperator(api.base as ApiBase).test(user)
    ops.extend(_mutateUserOps(user, changes))
    ops.output(user)
    return truthStore.update(await ops.do()).pop() as User
  }

  return {
    byUrl,
    users,
    requestUser,
    fetchUsers,
    currentUser,
    createUser,
    resetUser,
    changeUser,
  }
})
