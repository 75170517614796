import { customRef, readonly, Ref } from "vue"

type ReactiveMapParams<K, V> = {
  handleMissing?: (key: K) => V | undefined
  normalizeKey?: (key: string) => K
}

export default class ReactiveMap<K extends string, V> {
  /*
   * Reactive Map() implementation. get/has() return references to a reactive result
   * that will be updated on set(). getPrefix() tracks all keys with a given prefix.
   */
  private data: Map<K, V> = new Map()
  private deleted: Set<K> = new Set()
  private triggers: Map<K, Set<() => void>> = new Map()

  public constructor(protected options: ReactiveMapParams<K, V> = {}) {}

  protected triggerSet(prefix: K): Set<() => void> {
    if (!this.triggers.has(prefix)) {
      this.triggers.set(prefix, new Set())
    }
    return this.triggers.get(prefix)!
  }

  protected run_triggers(key?: K) {
    for (const [prefix, triggers] of this.triggers.entries()) {
      if (!key || key.startsWith(prefix)) {
        for (const trigger of triggers) {
          trigger()
        }
      }
    }
  }

  protected _handleMissing(key: K): V | undefined {
    if (this.options.handleMissing && !this.deleted.has(key)) {
      const newVal = this.options.handleMissing(key)
      if (newVal !== undefined) {
        this.set(key, newVal)
      }
      return newVal
    }
    return undefined
  }

  protected _normalizeKey(key: string) {
    return this.options.normalizeKey
      ? this.options.normalizeKey(key)
      : (key as K)
  }

  public has(key: K): Readonly<Ref<boolean>> {
    const key_ = this._normalizeKey(key)
    return readonly(
      customRef((track, trigger) => ({
        get: () => {
          track()
          this.triggerSet(key_).add(trigger)
          return this.data.has(key_)
        },
        set: () => {},
      })),
    )
  }

  public hasRightNow(key: K): boolean {
    const key_ = this._normalizeKey(key)
    return this.data.has(key_)
  }

  public getRightNow(key: K) {
    const key_ = this._normalizeKey(key)
    return this.data.get(key_)
  }

  public get<R extends V>(key: K): Ref<R | undefined> {
    const key_ = this._normalizeKey(key)
    return customRef<R | undefined>((track, trigger) => ({
      get: () => {
        track()
        this.triggerSet(key_).add(trigger)
        const retval = this.data.get(key_)
        if (retval === undefined) {
          return this._handleMissing(key_) as R
        }
        return retval as R
      },
      set: (value) => this.set(key_, value),
    }))
  }

  public set(key: K, value: V | undefined) {
    const key_ = this._normalizeKey(key)
    if (value === undefined) {
      this.data.delete(key_)
    } else {
      this.data.set(key_, value)
    }
    this.run_triggers(key_)
    return this
  }

  public prefixEntries(prefix: K) {
    const prefix_ = this._normalizeKey(prefix)
    return readonly(
      customRef((track, trigger) => ({
        set: () => {},
        get: () => {
          track()
          this.triggerSet(prefix_).add(trigger)
          return Array.from(this.data.entries()).filter(([k, v]) =>
            k.startsWith(prefix_),
          )
        },
      })),
    )
  }

  public clear() {
    this.data.clear()
    this.run_triggers()
  }

  public clearPrefix(prefix: K) {
    const prefix_ = this._normalizeKey(prefix)
    for (const key of this.data.keys()) {
      if (key.startsWith(prefix_)) {
        this.data.delete(key)
      }
    }
    this.run_triggers(prefix_)
  }

  public deleteWithPrejudice(key: K) {
    const key_ = this._normalizeKey(key)
    this.deleted.add(key_)
    this.data.delete(key_)
    this.run_triggers(key_)
  }

  public values() {
    return readonly(
      customRef<V[]>((track, trigger) => ({
        get: () => {
          track()
          this.triggerSet("" as K).add(trigger)
          return Array.from(this.data.values())
        },
        set: () => {},
      })),
    )
  }
}
