<template>
  <AuthOverlay />
</template>
<script setup lang="ts">
import AuthOverlay from "@/common/components/AuthOverlay.vue"
import { onMounted } from "vue"
import { useAuthStore } from "@/common/store/auth"
import { useRoute } from "vue-router"
import { ApiBase } from "@/common/lib/api"
import { trimStart } from "lodash"
import router from "@/plugins/router"

onMounted(async () => {
  const authStore = useAuthStore()
  const route = useRoute()
  if (route.name === "login") {
    await authStore.handleLogin(
      new ApiBase().normalizeUrl(
        "workstation/" + route.params.accessPointId + "/",
      ),
      trimStart(route.hash, "#"),
    )
    await router.replace({ name: "home" })
  } else if (route.name === "link") {
    let secret = trimStart(route.hash, "#")
    let pin = null as string | null
    if (secret.includes(",")) {
      ;[secret, pin] = secret.split(",", 2)
    }
    if (pin) {
      authStore.pin = pin
      authStore.pinState = "provided"
    }
    await authStore.handleLogin(
      new ApiBase().normalizeUrl("link/" + route.params.linkSlug + "/"),
      secret,
    )
    await router.replace({ hash: "" })
  } else if (!authStore.session) {
    await authStore.handleLogin()
  }
})
</script>
